import React, { useContext } from "react";
import "./footer.component.scoped.scss";
import { config } from "../../config";
import { GlobalContext, HomeContext } from "../../store";

export default function Footer(props: any) {
  const { links } = props;
  const { translation, configuration } = useContext(GlobalContext);
  const { activeStep } = useContext(HomeContext);

  return (
    <div className="footer">
      <div className="theme-container">
        <div className="content">
          <p>©2022 Compliance2b GmbH</p>
          <div className="links">
            {links && activeStep === 0 && (
              <ul>
                {configuration && configuration.footer_link1 && (
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href={configuration.footer_link1}
                      target="_blank"
                    >
                      {translation.create["footer-text-btn1"]}
                    </a>
                  </li>
                )}
                {configuration && configuration.footer_link2 && (
                  <li>
                    <a
                      rel="noopener noreferrer"
                      href={configuration.footer_link2}
                      target="_blank"
                    >
                      {translation.create["footer-text-btn2"]}
                    </a>
                  </li>
                )}
              </ul>
            )}
          </div>
          <div className="version">
            <p className="m-0">V.{config.appVersion}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
