import React, { useContext, useState } from "react";
import "./communication-tab.component.scoped.scss";
import { CommunicationTimelineComponent } from "../communication-timeline/communication-timeline.component";
import { FilesUploadSectionComponent } from "../files-upload-section/files-upload-section.component";
import FilesDropZone from "../files-drop-zone/files-drop-zone.component";
import { CaseContext } from "../../store";

export const CommunicationTabComponent = (props: any) => {
  const [files, setFiles] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const { caseDetails, completedStatusId } = useContext(CaseContext);

  return (
    <FilesDropZone
      onDropFiles={setFiles}
      disabled={caseDetails.statusId === completedStatusId || isSaving}
    >
      <div className="theme-container communication-tab">
        <CommunicationTimelineComponent />
      </div>
      <div
        className="sticky-container"
        style={{
          display:
            caseDetails.statusId === completedStatusId ? "none" : "block",
        }}
      >
        <div className="theme-container">
          <FilesUploadSectionComponent
            droppedFiles={files}
            onUploadingFiles={setIsSaving}
          />
        </div>
      </div>
    </FilesDropZone>
  );
};
