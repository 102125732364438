import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { CaseContext } from "../../store";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";

function a11yProps(index: any) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tab-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    // left: "-10px",
  },
}));

export default function CaseTabsComponent() {
  const classes = useStyles();
  const [t] = useTranslation();
  const { activeTab, setActiveTab } = React.useContext(CaseContext);
  const isMobile = useMediaQuery("(max-width:576px)");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs value={activeTab} onChange={handleChange} aria-label="case tabs">
        <Tab label={t("case.description")} {...a11yProps(0)} className="tab-btn" />
        <Tab
          label={isMobile ? t("case.communication") : t("case.communication-files")}
          {...a11yProps(1)}
          className="tab-btn"
        />
      </Tabs>
    </div>
  );
}
