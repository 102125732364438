import React, { useContext, useEffect, useState } from "react";
import "./case-content.component.scoped.scss";
import { CaseContext, GlobalContext } from "../../store";
import { CasesService } from "../../services/cases/cases.service";
import { MapperService } from "../../services/mapper/mapper.service";
import CaseHeaderComponent from "../case-header/case-header.component";
import CaseHeaderStickyComponent from "../case-header-sticky/case-header-sticky.component";
import CaseTabContentComponent from "../case-tab-content/case-tab-content.component";
import { dummyComments } from "../../statics/dummy-comments";
import { deepClone } from "../../helpers";
import { Case } from "../../types/case";
import withReactContent from "sweetalert2-react-content";
import { alertToastConfig } from "../../statics/alert-config";
import Swal from "sweetalert2";
import { history } from "react-router-guard";
import { useTranslation } from "react-i18next";

export default function CaseContent(props: any) {
  const casesService = new CasesService();
  const mapperService = new MapperService();
  const [t] = useTranslation();
  const emptyArr: any = [];
  const [cachedStatuses, setCachedStatuses] = useState(emptyArr);
  const { secretKey } = props;
  const { setCase, setLoading, setCommunicationsLoading, setCaseStatuses, setCaseComments, activeTab, caseDetails }
    = useContext(CaseContext);
  const { configuration } = useContext(GlobalContext);

  const showAlertMsg = (message: string) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      icon: "error",
      title: message,
      ...alertToastConfig,
    });
  };

  async function fetchCaseDetails(id: string) {
    setLoading(true);
    const caseDetailsData: Case = await casesService.searchCase(id);
    if (!(caseDetailsData && caseDetailsData.id)) {
      showAlertMsg(t("case.case doesn't exist"));
      history.push(`/track`);
      return;
    }
    const statuses = await casesService.listStatuses();
    const mappedStatuses = mapperService.mapStatuses(statuses);
    const { mappedCase, mappedtimelineStatuses } = mapperService.mapCase(caseDetailsData, mappedStatuses, configuration.logoUrl);
    mappedCase.secretKey = secretKey;
    setCase(mappedCase);
    setCaseStatuses(mappedtimelineStatuses);
    setCachedStatuses(deepClone(mappedtimelineStatuses));
    setLoading(false);
  }

  async function fetchCaseComments(id: string) {
    setCommunicationsLoading(true);
    setCaseComments(dummyComments);
    const caseComments = await casesService.caseComments(id);
    setTimeout(() => {
      setCaseComments(caseComments);
      setCommunicationsLoading(false);
    }, 100);
  }

  useEffect(() => {
    fetchCaseDetails(secretKey);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    switch (activeTab) {
      // Description Tab
      case 0:
        fetchCaseDetails(secretKey);
        break;
      // Communications Tab
      case 1:
        setCaseStatuses(
          mapperService.mapTimelineStatus(
            caseDetails,
            deepClone(cachedStatuses)
          )
        );
        fetchCaseComments(secretKey);
        break;
      default:
        break;
    }

    // eslint-disable-next-line
  }, [activeTab]);

  return (
    <div className="case-content">
      <div className="case-header-content">
        <CaseHeaderComponent />
      </div>
      <div className="case-header-content sticky-header">
        <CaseHeaderStickyComponent />
      </div>
      <div className="case-tabs-content">
        <CaseTabContentComponent />
      </div>
    </div>
  );
}

// "case": "Meldung",

