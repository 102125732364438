import React, { useContext } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import "./create-case-stepper.component.scoped.scss";
import { StepButton } from "@material-ui/core";
import { GlobalContext, HomeContext } from "../../store";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function CreateCaseStepper() {
  // const [t] = useTranslation();
  const { translation } = useContext(GlobalContext);
  const { activeStep, setActiveStep } = useContext(HomeContext);
  const isTablet = useMediaQuery("(max-width:768px)");

  const steps = [
    translation.create['choose country/company'],
    translation.record['record case'],
    translation.check['check case'],
    translation.download['receive access data']
  ];

  const mobileSteps = [
    translation.create['create'],
    translation.record['record'],
    translation.check['check'],
    translation.download['download']
  ];

  const handleStep = (index: number) => {
    if (index < activeStep && activeStep < 3) {
      setActiveStep(index);
    }
  };

  return activeStep !== 0 ? (
    <div className="case-stepper">
      {isTablet ? (
        <div className="steps-wrapper d-flex flex-row align-items-center justify-content-start cursor-pointer">
          {mobileSteps.map((label: string, index: number) => (
            <div
              key={index}
              className="step d-flex flex-column align-items-center justify-content-center"
              style={{
                background: `${activeStep >= index ? "rgb(170, 205, 52)" : "rgb(90, 92, 79)"
                  }`,
                cursor: `${activeStep >= index && activeStep < 3 ? "pointer" : ""
                  }`,
              }}
              onClick={handleStep.bind(null, index)}
            >
              <div className="index">{index + 1}</div>
              <div className="label">{label}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="theme-container">
          <div className="d-flex justify-content-center align-items-center">
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label: string, index: number) => (
                <Step key={index} className="case-step">
                  <StepButton
                    completed={false}
                    onClick={handleStep.bind(null, index)}
                    disabled={
                      index >= activeStep || activeStep === steps.length - 1
                    }>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
      )}
    </div>
  ) : null;
}
