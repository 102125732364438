import React from "react";
// import { useTranslation } from "react-i18next";
import HeaderComponent from "../../components/header/header.component";
import CaseContent from "../../components/case-content/case-content.component";
import { CaseProvider } from "../../store";
import { SessionStorageService } from "../../services/session-storage/session-storage";
// import { CommonService } from "../../services/common/common.service";

export default function CasePage(props: any) {
  // const [t] = useTranslation();
  // const commonService: CommonService = new CommonService();
  // commonService.setTitle(t("case"));
  const sessionStorageService: SessionStorageService = new SessionStorageService();
  const secretKey = sessionStorageService.getData("caseInternalId");
  return (
    <CaseProvider>
      <HeaderComponent />
      <CaseContent secretKey={secretKey} />
    </CaseProvider>
  );
}
