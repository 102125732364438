import HomePage from "../pages/home/home.page";
import CasePage from "../pages/case/case.page";
import TrackCasePage from "../pages/track-case/track-case.page";
import { GuardsService } from "../services/guards/guards.service";
import DashboardLayout from "../layouts/dashboard.layout";
const guardsService: GuardsService = new GuardsService();

export default [
  {
    path: "/",
    component: DashboardLayout,
    routes: [
      {
        path: "/activate/:key",
        component: HomePage,
      },
      {
        path: "/track",
        component: TrackCasePage,
      },
      {
        path: "/case",
        component: CasePage,
        canActivate: [guardsService.isCaseActive],
      },
      {
        path: "/temp",
      },
      {
        path: "/",
        component: HomePage,
      },
      {
        path: "*",
        redirect: "/",
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];
