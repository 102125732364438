import { config } from "../../config";
export class SessionStorageService {

  public getData(key: string) {
    const { storagePrefix } = config;
    const data = sessionStorage.getItem(`paladin-reporter-${storagePrefix}-${key}`);
    return data ? JSON.parse(data) : '';
  }
  public setData(key: string, data: any) {
    const { storagePrefix } = config;
    sessionStorage.setItem(`paladin-reporter-${storagePrefix}-${key}`, JSON.stringify(data));
  }
  public removeData(key: string) {
    const { storagePrefix } = config;
    sessionStorage.removeItem(`paladin-reporter-${storagePrefix}-${key}`);
  }
  public clearAll() {
    sessionStorage.clear();
  }
}
