export class Case {
  public category: string = "";
  public client: string = "";
  public createdAt: string = "";
  public dueDate: Date = new Date();
  public id: number = 0;
  public secretKey: string = "";
  public status: string = "";
  public statusId: string = "";
  public statusColor: string = "";
  public originalDescription: string = "";
  public optionalFieldsEmpty: boolean = false;
  public originalAmount: string = "";
  public originalEndDate: string = "";
  public originalInvolvedCompany: string = "";
  public originalInvolvedDepartment: string = "";
  public originalInvolvedPersons: string = "";
  public originalLand: string = "";
  public originalRelationship: string = "";
  public originalStartDate: string = "";
  public resolution: string = "";
  public resolutionComment: string = "";
  public duration: string = "";
  public clientIcon: string = "";
}
