export default (state: any, action: any) => {
  switch (action.type) {
    case "SET_ACTIVE_STEP":
      return {
        ...state,
        activeStep: action.payload.activeStep,
      };
    case "SET_CASE_FORM":
      return {
        ...state,
        caseForm: action.payload.caseForm,
      };
    case "SET_BRANCHES":
      return {
        ...state,
        branches: action.payload.branches,
      };
    case "SET_LANDS":
      return {
        ...state,
        lands: action.payload.lands,
      };
    case "SET_CATEGORIES":
      return {
        ...state,
        categories: action.payload.categories,
      };
    case "SET_AMOUNTS":
      return {
        ...state,
        amounts: action.payload.amounts,
      };
    case "SET_RELATIONS":
      return {
        ...state,
        relations: action.payload.relations,
      };
    case "SET_CASE_KEY":
      return {
        ...state,
        caseSecretKey: action.payload.caseSecretKey,
      };
    default:
      return state;
  }
};

