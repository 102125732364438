import React, { useContext } from "react";
import "./case-header.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { CaseContext } from "../../store";
import Skeleton from "@material-ui/lab/Skeleton";
import { formatDate } from "../../helpers";
import { icons } from "../../statics/icons";
import { CaseInfoComponent } from "../case-info/case-info.component";

export default function CaseHeaderComponent(props: any) {
  const [t] = useTranslation();
  const { caseDetails, loading } = useContext(CaseContext);

  return (
    <div className="case-header case-container w-100">
      <div className="header-wrapper no-gutters">
        <div className="left-header">
          <div className="case-info">
            {loading ? (
              <Skeleton variant="text" height={35} width={400} />
            ) : (
              <div className="case-status">
                <div className="status-label mr-2">{t("case.status")}:</div>
                <div
                  className="status-color"
                  style={{ backgroundColor: caseDetails.statusColor }}
                />
                <div className="status-text">
                  {caseDetails.status?.toLowerCase()}
                </div>
              </div>
            )}
            <div className="d-flex">
              <div className="left-card mr-left-card">
                <CaseInfoComponent
                  label={t("case.category")}
                  value={caseDetails.category}
                  loading={loading}
                />
              </div>
              <div className="left-card">
                <CaseInfoComponent
                  label={t("case.created-date")}
                  value={formatDate(caseDetails.createdAt, "DD.MM.YYYY")}
                  loading={loading}
                />
              </div>
            </div>
          </div>
          {loading ? (
            <Skeleton variant="text" height={25} width={300} />
          ) : (
            caseDetails.resolution && (
              <>
                <div className="d-flex">
                  <div className="resolution-label mr-1">{t("case.results")}:</div>
                  <div className="resolution-text">
                    {caseDetails.resolution}
                  </div>
                </div>
                {caseDetails.resolutionComment && (
                  <div className="d-flex mb-1">
                    <div className="resolution-label mr-1">
                      {t("case.results-comment")}:
                    </div>
                    <div className="resolution-text">
                      {caseDetails.resolutionComment}
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </div>
        <div className="right-header">
          <div className="w-100 mb-3">
            <CaseInfoComponent
              image={caseDetails.clientIcon}
              label={t("case.companies")}
              value={caseDetails.branchName}
              loading={loading}
            />
          </div>
          <div className="w-100 mb-3">
            <CaseInfoComponent
              icon={icons.country}
              label={t("case.country")}
              value={caseDetails.originalLand}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
