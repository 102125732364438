import React, { createContext, useReducer } from "react";
import CaseReducer from "../reducers/case.reducer";
import { Case } from "../../types/case";

const initialState: any = {
  caseDetails: new Case(),
  loading: false,
  activeTab: 1,
  communicationsLoading: false,
  caseStatuses: [],
  caseComments: [],
  completedStatusId: 4,
};

export const CaseContext = createContext(initialState);
export const CaseProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(CaseReducer, initialState);

  function setCase(caseDetails: Case) {
    dispatch({
      type: "SET_CASE",
      payload: { caseDetails },
    });
  }

  function setActiveTab(activeTab: number) {
    dispatch({
      type: "SET_ACTIVE_TAB",
      payload: { activeTab },
    });
  }

  function setCaseComments(caseComments: any[]) {
    dispatch({
      type: "SET_CASE_COMMENTS",
      payload: { caseComments },
    });
  }

  function setCaseStatuses(caseStatuses: any[]) {
    dispatch({
      type: "SET_CASE_STATUSES",
      payload: { caseStatuses },
    });
  }

  function setLoading(loading: boolean) {
    dispatch({
      type: "SET_LOADING",
      payload: { loading },
    });
  }

  function setCommunicationsLoading(communicationsLoading: boolean) {
    dispatch({
      type: "SET_COMMUNICATIONS_LOADING",
      payload: { communicationsLoading },
    });
  }

  return (
    <CaseContext.Provider
      value={{
        ...state,
        setLoading,
        setCase,
        setActiveTab,
        setCaseStatuses,
        setCaseComments,
        setCommunicationsLoading,
      }}
    >
      {children}
    </CaseContext.Provider>
  );
};
