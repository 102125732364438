import React, { useContext, useEffect, useMemo, useState } from "react";
import "./header.component.scoped.scss";
import { icons } from "../../statics/icons";
import Dropdown from "react-bootstrap/Dropdown";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { history } from "react-router-guard";
import { GlobalContext, HomeContext } from "../../store";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import { SessionStorageService } from "../../services/session-storage/session-storage";
import { Button } from "@material-ui/core";
import Countdown from "react-countdown";
import { CaseForm } from "../../types/case-form";

export default function HeaderComponent(props: any) {
  const localStorageService: LocalStorageService = new LocalStorageService();
  const sessionStorageService: SessionStorageService = new SessionStorageService();

  const { setLang, lang, translation, languages } = useContext(GlobalContext);
  const { activeStep, setActiveStep, setCaseForm } = useContext(HomeContext);
  const [restartTimeout, setRestartTimeout] = useState(0);

  let routeName = "/";
  if (history.location.pathname !== "/temp") {
    routeName = history.location.pathname;
  }

  const selectLang = (newLang: string) => {
    if (lang === newLang) return;
    localStorageService.setData("lang", newLang);
    setLang(newLang);
    history.replace("/temp");
    setTimeout(() => {
      history.replace(routeName);
    });
  };

  // scroll to to on history change
  useEffect(() => {
    const scrollable: any = document.querySelector(".main-wrapper");
    const unlisten = history.listen(() => {
      if (
        history.location.pathname !== "/case" &&
        history.location.pathname !== "/temp"
      ) {
        sessionStorageService.removeData("caseInternalId");
      }
      if (scrollable) {
        scrollable.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = (route: string = "/") => {
    history.push(route);
  };

  const rendererCounter = (e: any) => {
    const { minutes, seconds, completed } = e;
    if (completed) {
      localStorageService.clearAll();
      if (
        history.location.pathname !== "/case" &&
        history.location.pathname !== "/track"
      ) {
        setActiveStep(0);
        setCaseForm(new CaseForm());
      }
      history.replace("/temp");
      setTimeout(() => {
        history.replace("/");
      });
      setTimeout(() => {
        setRestartTimeout(restartTimeout + 1);
      }, 1000);
    }
    return (
      <span>
        00:{minutes < 10 ? "0" + minutes : minutes}:
        {seconds < 10 ? "0" + seconds : seconds}
      </span>
    );
  };

  const CountdownComponent = useMemo(() => {
    return (
      <Countdown
        date={Date.now() + 45 * 60 * 1000}
        key={restartTimeout}
        renderer={rendererCounter}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restartTimeout]);

  return (
    <header className="main-header">
      <div className="header-content">
        <div className="left-content">
          <div className="main-logo">
            <img
              src={icons.logo}
              alt="logo"
              className=""
              onClick={navigate.bind(null, "/")}
            />
          </div>
          <div className="header-button">
            {!routeName.includes("case") &&
              activeStep === 0 &&
              !routeName.includes("track") && (
                <Button
                  startIcon={<img src={icons.trackReportLight} alt="" />}
                  onClick={navigate.bind(null, "/track")}
                >
                  {translation.create["track-case"]}
                </Button>
              )}
            {routeName.includes("case") && (
              <Button
                startIcon={<img src={icons.addLight} alt="" />}
                onClick={navigate.bind(null, "/")}
              >
                {translation.create["new-case"]}
              </Button>
            )}
          </div>
        </div>
        <div className="right-content">
          <div className="timer">
            <p className="m-0">{translation.create["login-counter"]}:</p>
            {CountdownComponent}
          </div>
          {languages.length > 1 && (
            <div className="header-item language">
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-secondary"
                  className="language-switch-btn"
                >
                  {/* <img src={icons[lang]} alt="" /> */}
                  <div className="language-name">{lang.toUpperCase()}</div>
                  <ExpandMoreIcon className="expand-more" />
                  <ExpandLessIcon className="expand-less" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {languages.map((item: any, index: number) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        onClick={selectLang.bind(null, item.key)}
                      >
                        {/* <img
                          src={icons[item.name]}
                          alt={item.name}
                          title={item.name}
                        /> */}
                        {item.key}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
      {props.children}
    </header>
  );
}
