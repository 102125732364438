export default (state: any, action: any) => {
  switch (action.type) {
    case "SET_CASE":
      return {
        ...state,
        caseDetails: action.payload.caseDetails,
      };
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.payload.activeTab,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "SET_COMMUNICATIONS_LOADING":
      return {
        ...state,
        communicationsLoading: action.payload.communicationsLoading,
      };
    case "SET_CASE_STATUSES":
      return {
        ...state,
        caseStatuses: action.payload.caseStatuses,
      };
    case "SET_CASE_COMMENTS":
      return {
        ...state,
        caseComments: action.payload.caseComments,
      };
    default:
      return state;
  }
};
