import React, { useContext, useEffect, useState } from "react";
import { ThemeProvider } from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { ConfigurationService } from "../services/configuration/configuration.services";
import { GlobalContext } from "../store";
import { LangService } from "../services/lang/lang.service";
import { LocalStorageService } from "../services/local-storage/local-storage";
import { ServerMessage } from "../components/server-message/server-message.component";
import { history } from "react-router-guard";

function getFaviconEl() {
  return document.getElementById("favicon");
}

export default function DashboardLayout(props: any) {
  const configurationService = new ConfigurationService();
  const langService: LangService = new LangService();
  const localStorageService: LocalStorageService = new LocalStorageService();

  const {
    setConfiguration,
    setTranslation,
    setLanguages,
    setLang,
    languages,
    configuration,
  } = useContext(GlobalContext);
  const [mainColor, setMainColor] = useState("#f2af1d");

  const getConfiguration = async () => {
    const configuration = await configurationService.getConfiguration();
    setMainColor(
      configuration.main_color ? configuration.main_color : "#f2af1d"
    );
    document.documentElement.style.setProperty(
      "--primary-color",
      configuration.main_color ? configuration.main_color : mainColor
    );
    document.documentElement.style.setProperty(
      "--input-focus-color",
      configuration.main_color ? configuration.main_color : mainColor
    );
    setConfiguration(configuration);

    handleFavIco(configuration.logoUrl);
  };

  const getTranslation = async () => {
    const response = await langService.getTranslation();
    setTranslation(response.translations);
    setLanguages(response.languages);
    if (response.languages.length === 1) {
      localStorageService.setData("lang", response.languages[0].key);
      setLang(response.languages[0].key);
    }
  };

  const innerTheme = createMuiTheme({
    palette: {
      primary: {
        main: mainColor,
        light: "#f6c761",
        dark: mainColor,
        contrastText: "#fff",
      },
    },
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            color: "rgb(128, 128, 128)",
            opacity: 1,
          },
        },
      },
    },
  });

  const handleFavIco = (logoUrl: string) => {
    const favicon: any = getFaviconEl();

    if (logoUrl) {
      favicon.href = logoUrl;
    } else if (window.location.host.includes("grc-experts.report")) {
      favicon.href = "/fav/grc-expert.png";
    } else if (window.location.host.includes("compliance2b.com")) {
      favicon.href = "/fav/moweex.png";
    }
  };

  const close = () => {
    setConfiguration({ ...configuration, system_unavailable: 0 });
  };

  useEffect(() => {
    getConfiguration();
    getTranslation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // scroll to to on history change
  useEffect(() => {
    const unlisten = history.listen((e: any) => {
      if (e.pathname !== "/temp") {
        getTranslation();
      }
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ThemeProvider theme={innerTheme}>
        {configuration && !!+configuration.system_unavailable && (
          <ServerMessage close={close} />
        )}
        {languages.length !== 0 && props.children}
      </ThemeProvider>
    </>
  );
}
