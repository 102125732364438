import React from "react";
import { RouterGuard } from "react-router-guard";
import router from "./router";
import "./app.component.scoped.scss";
import { GlobalProvider } from "./store";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

function App() {
  return (
    <GlobalProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className="main-wrapper">
          <RouterGuard config={router} />
        </div>
      </MuiPickersUtilsProvider>
    </GlobalProvider>
  );
}

export default App;
