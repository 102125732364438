import axios from "axios";
import { config } from "../../config";

export class CasesService {
  /*********** Lookups ***********/
  public listStatuses = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "cases/status");
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public listCategories = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "categories");
      return response.data;
    } catch (err) {
      return [];
    }
  };

  public listBranches = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "branches");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public listLands = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "lands");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public listCaseAmounts = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "amounts");
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public listCaseRelations = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "relationships");
      return response.data;
    } catch (error) {
      return [];
    }
  };
  /*********** End Lookups ***********/

  /***********  Create Case ***********/
  public createCase = async (payload: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "cases/create",
        payload
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public activateAccount = async (id: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "cases/activate",
        { id }
      );
      return response.data;
    } catch (error) {
      return {};
    }
  }

  public searchCase = async (secretKey: string): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "cases/" + secretKey);
      return response.data;
    } catch (error) {
      return {};
    }
  };

  /*********** End Create Case ***********/

  /*********** Begin Communication Tab ***********/
  public caseComments = async (secretKey: string, items?: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      let params = {};
      if (items) {
        params = { items };
      }
      const response = await axios.get(
        serverUrl + "cases/" + secretKey + "/communication",
        { params }
      );
      return response.data;
    } catch (error) {
      return [];
    }
  };

  public addCommunicationComment = async (
    secretKey: string,
    statusId: number,
    files: any[],
    comment: string
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "cases/" + secretKey + "/add-comment",
        {
          statusId,
          files,
          comment
        }
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };
  /*********** End Communication Tab ***********/

  public requestSignedUrl = async (
    fileName: string,
    fileType: string,
    caseId: string
  ): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "cases/sign-file",
        {
          fileName,
          fileType,
          caseId
        }
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public uploadToSignedURL = async (
    signedRequest: string,
    file: any,
    cancelToken: any,
    onUploadProgress: any
  ): Promise<any> => {
    try {
      const response = await axios.put(signedRequest, file, {
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        cancelToken,
        onUploadProgress,
      });
      return response.data;
    } catch (error) {
      return "canceled";
    }
  };
}
