import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./track-case-content.component.scoped.scss";
import { Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { CasesService } from "../../services/cases/cases.service";
import { Case } from "../../types/case";
import withReactContent from "sweetalert2-react-content";
import { alertToastConfig } from "../../statics/alert-config";
import Swal from "sweetalert2";
import { history } from "react-router-guard";
import { SessionStorageService } from "../../services/session-storage/session-storage";
import useMediaQuery from "@material-ui/core/useMediaQuery";


export default function TrackCaseContent() {
  const [ticketNumber, setTicketNumber] = useState("");
  const [t] = useTranslation();
  const casesService: CasesService = new CasesService();
  const sessionStorageService: SessionStorageService = new SessionStorageService();
  const maxLength = 19;
  const handleTicketNumber = (e: any) => {
    let ticket: string = e.target.value;
    const regex = /^[0-9\b-]+$/;
    const firstChars = ticket.substr(0, ticket.length - 1);
    const lastChar = ticket[ticket.length - 1];
    if (ticket === "" || regex.test(ticket)) {
      if (ticket && ticket.length % 5 === 0 && lastChar !== "-") {
        ticket = `${firstChars}-${lastChar}`;
      }
      setTicketNumber(ticket);
    }
  };
  const isMobile = useMediaQuery("(max-width:576px)");

  const showAlertMsg = (message: string) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      icon: "error",
      title: message,
      ...alertToastConfig,
    });
  };

  const searchCase = async () => {
    const caseDetails: Case = await casesService.searchCase(ticketNumber);
    if (caseDetails && caseDetails.id) {
      sessionStorageService.setData("caseInternalId", ticketNumber);
      history.push("/case");
    } else {
      showAlertMsg(t("track.case doesn't exist"));
    }
  };

  return (
    <div className="track-case-content" >
      <div className="track-case-header">
        <div className="header-title">
          {t("track.thank you for staying in touch with us")}
        </div>
      </div>
      <div className="ticket-entry mx-auto">
        <div className="wrapper">
          <div className="entry-text">
            {t("track.identify yourself with your access data")}
          </div>
          <div className="w-100" >
            <TextField
              onChange={handleTicketNumber}
              value={ticketNumber}
              name="ticketNumber"
              fullWidth
              label={t("track.ticket number")}
              variant="filled"
              inputProps={{
                maxLength: maxLength,
              }}
              autoComplete='off'
            />
          </div>
          <div className="continue-button">
            <Button
              className="btn-primary-theme continue-btn"
              variant="contained"
              color="primary"
              disabled={ticketNumber.length !== maxLength}
              onClick={searchCase}
            >
              <ArrowForwardIcon /> {t("track.continue")}
            </Button>
          </div>
        </div>
      </div>
      {isMobile && <div style={{ height: "60%" }} />}
    </div>
  );
}
