import { icons } from "./icons";

export const caseDetailsInfo: any[] = [
  {
    icon: icons.involvedPeople,
    value: "originalInvolvedPersons",
    title: "case.involved-people",
  },
  {
    icon: icons.amountOfDamage,
    value: "originalAmount",
    title: "case.estimated-amount-damage",
  },
  {
    icon: icons.involvedDepartments,
    value: "originalInvolvedDepartment",
    title: "case.involved-department",
  },
  {
    icon: icons.date,
    value: "duration",
    title: "case.duration",
  },
  {
    icon: icons.company,
    value: "originalInvolvedCompany",
    title: "case.involved-company",
  },
  {
    icon: icons.relation,
    value: "originalRelationship",
    title: "case.relation",
  },
];
