import { icons } from "./icons";

export const caseReporterInfo: any[] = [
    {
        icon: icons.involvedPeople,
        value: "reporterName",
        title: "case.name of hint",
    },
    {
        icon: icons.involvedPeople,
        value: "reporterEmail",
        title: "case.note given email",
    }
];
