import i18n from "../../i18n";

export default (state: any, action: any) => {
  const { lang, translation, languages, configuration } = action.payload;
  switch (action.type) {
    case "SET_LANG":
      setTimeout(() => {
        i18n.changeLanguage(lang.toLowerCase() === "en" ? "en" : "de");
      }, 0);
      return {
        ...state,
        lang,
      };
    case "SET_TRANSLATION":
      return {
        ...state,
        translation,
      };
    case "SET_LANGUAGES":
      return {
        ...state,
        languages,
      };
    case "SET_CONFIGURATION":
      return {
        ...state,
        configuration,
      };
    default:
      return state;
  }
};
