import React, { createContext, useReducer } from "react";
import { CaseForm } from "../../types/case-form";
import HomeReducer from "../reducers/home.reducer";

const initialState: any = {
  activeStep: 0,
  caseForm: new CaseForm(),
  branches: [],
  lands: [],
  categories: [],
  amounts: [],
  relations: [],
  caseSecretKey: ""
};

export const HomeContext = createContext(initialState);
export const HomeProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(HomeReducer, initialState);

  function setActiveStep(activeStep: number) {
    dispatch({
      type: "SET_ACTIVE_STEP",
      payload: { activeStep },
    });
  }

  function setCaseForm(caseForm: CaseForm) {
    dispatch({
      type: "SET_CASE_FORM",
      payload: { caseForm },
    });
  }

  function setBranches(branches: any[]) {
    dispatch({
      type: "SET_BRANCHES",
      payload: { branches },
    });
  }

  function setLands(lands: any[]) {
    dispatch({
      type: "SET_LANDS",
      payload: { lands },
    });
  }

  function setCategories(categories: any[]) {
    dispatch({
      type: "SET_CATEGORIES",
      payload: { categories },
    });
  }

  function setAmounts(amounts: any[]) {
    dispatch({
      type: "SET_AMOUNTS",
      payload: { amounts },
    });
  }

  function setRelations(relations: any[]) {
    dispatch({
      type: "SET_RELATIONS",
      payload: { relations },
    });
  }

  function setCaseSecretKey(caseSecretKey: any) {
    dispatch({
      type: "SET_CASE_KEY",
      payload: { caseSecretKey },
    });
  }

  return (
    <HomeContext.Provider
      value={{
        ...state,
        setActiveStep,
        setCaseForm,
        setBranches,
        setLands,
        setCategories,
        setAmounts,
        setRelations,
        setCaseSecretKey
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
