export const dummyComments = [
  {
    status: "EINGELANGT",
    comments:
      [
        {
          user: null,
        },
        {
          user: "Michael",
        },
        {
          user: null,
        },
        {
          user: "Michael",
        },
        {
          user: null,
        },
      ]
  },
  {
    status: "IN BEARBEITUNG",
    comments:
    [
      {
        user: null,
      },
      {
        user: "Michael",
      },
      {
        user: null,
      },
      {
        user: "Michael",
      },
      {
        user: null,
      },
    ]
  },
  {
    status: "REVIEW",
    comments:
    [
      {
        user: null,
      },
      {
        user: "Michael",
      },
      {
        user: null,
      },
      {
        user: "Michael",
      },
      {
        user: null,
      },
    ]
  },
  {
    status: "ABGESCHLOSSEN",
    comments:
    [
      {
        user: null,
      },
      {
        user: "Michael",
      },
      {
        user: null,
      },
      {
        user: "Michael",
      },
      {
        user: null,
      },
    ]
  },
];