import { config } from "../../config";
export class LocalStorageService {

  public getData(key: string) {
    const { storagePrefix } = config;
    const data = localStorage.getItem(`paladin-reporter-${storagePrefix}-${key}`);
    return data ? JSON.parse(data) : '';
  }
  public setData(key: string, data: any) {
    const { storagePrefix } = config;
    localStorage.setItem(`paladin-reporter-${storagePrefix}-${key}`, JSON.stringify(data));
  }
  public removeData(key: string) {
    const { storagePrefix } = config;
    localStorage.removeItem(`paladin-reporter-${storagePrefix}-${key}`);
  }
  public clearAll() {
    localStorage.clear();
  }
}
