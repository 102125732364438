import React from 'react';
import './track-case.page.scoped.scss'
import HeaderComponent from "../../components/header/header.component";
import TrackCaseContent from '../../components/track-case-content/track-case-content.component';
import { CaseProvider } from '../../store';
import Footer from '../../components/footer/footer.component';

export default function TrackCasePage() {
  return (
    <CaseProvider>
      <HeaderComponent />
      <TrackCaseContent />
      <Footer links={false} />
    </CaseProvider>
  );
}