import { icons } from "./icons";

export const statusIcons: any[] = [
  {
    white: icons.received,
    orange: icons.receivedOrange,
    grey: icons.receivedGrey
  },
  {
    white: icons.inProgress,
    orange: icons.inProgressOrange,
    grey: icons.inProgressGrey
  },
  {
    white: icons.review,
    orange: icons.reviewOrange,
    grey: icons.reviewGrey
  },
  {
    white: icons.completed,
    orange: icons.completedOrange,
    grey: icons.completedGrey
  }
];