import React from "react";
import "./card.component.scoped.scss";
import { icons } from "../../statics/icons";
import IconButton from "@material-ui/core/IconButton";
import Skeleton from "@material-ui/lab/Skeleton";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const CardComponent = (props: any) => {
  const { title, subTitle, icon, isEditable, editEmitter, loading } = props;
  const isMobile = useMediaQuery("(max-width:576px)");
  return (
    <div className={"case-card " + (isEditable ? "active" : "")}>
      {loading ? (
        <Skeleton
          variant="circle"
          height={50}
          width={50}
          style={{ marginRight: "20px" }}
        />
      ) : (
        <div className="case-card-icon">
          <img src={icon} alt="" />
        </div>
      )}
      <div className="case-card-info">
        <div
          className={
            "d-flex justify-content-between align-items-center " + isMobile
              ? "mb-3 mt-2"
              : loading || subTitle
              ? "mb-2"
              : "mb-3 mt-3"
          }
        >
          <div className="case-card-head">
            {loading ? (
              <Skeleton variant="text" height={25} />
            ) : (
              <div className="case-card-title">{title}</div>
            )}
            {loading ? (
              <Skeleton variant="text" height={25} />
            ) : (
              <div className="case-card-subtitle">{subTitle}</div>
            )}
          </div>
          {isEditable && !loading && (
            <div className="case-card-edit" onClick={editEmitter}>
              <IconButton aria-label="edit">
                <img src={icons.edit} alt="" />
              </IconButton>
            </div>
          )}
        </div>
        <div className="case-card-body">{props.children}</div>
      </div>
    </div>
  );
};
