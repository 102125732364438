import React, { useEffect, useRef } from "react";
import "./files-upload-section.component.scoped.scss";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CasesService } from "../../services/cases/cases.service";
import { useTranslation } from "react-i18next";
import { CaseContext } from "../../store";
import {
  TextField,
  InputAdornment,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { icons } from "../../statics/icons";
import { FileSizes } from "../../statics/file-sizes";
import { alertToastConfig } from "../../statics/alert-config";
import { turncate } from "../../helpers";
import { fileTypes } from "../../statics/file-types";
import { dummyComments } from "../../statics/dummy-comments";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f2af1d",
    color: "#f5f6f6",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export const FilesUploadSectionComponent = (props: any) => {
  const {
    caseDetails,
    setCaseComments,
    setCommunicationsLoading,
  } = React.useContext(CaseContext);
  const maxChar = 500;
  const maxFiles = 5;
  const emptyArr: any = [];
  const [t] = useTranslation();
  const casesService = new CasesService();
  const [comment, setComment] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const [filesListProgress, setFilesListProgress] = React.useState(emptyArr);
  const [filesList, setFilesList] = React.useState(emptyArr);
  const [filesListCanceled, setFilesListCanceled] = React.useState(emptyArr);
  const inputFileRef = useRef(null);
  const uploadEl = useRef(null);
  const isMobile = useMediaQuery("(max-width:576px)");

  const handleChange = (event: any) => {
    const value = event?.target?.value;
    setComment(value);
  };

  const isValid = (comment: string = "") => {
    return comment && comment.length <= maxChar;
  };

  const submit = async () => {
    if (!saving && isValid(comment) && filesList.length <= maxFiles) {
      setSaving(true);
      props.onUploadingFiles(true);
      const filesSavedOrCanceled: any[] = [];
      const filesProgress: any[] = [];
      const filesCanceled: any[] = [];
      const filesUploaded: any[] = [];
      if (filesList.length) {
        filesList.forEach((file: any, index: number) => {
          casesService.requestSignedUrl(file.name, getFileInfo(file.type).fileType, caseDetails.internalId)
            .then((response: any) => {
              const { signedRequest, url } = response;
              const cancelTokenSource = axios.CancelToken.source();
              filesList[index].cancelTokenSource = cancelTokenSource;
              filesList[index].url = url;
              casesService.uploadToSignedURL(
                signedRequest,
                filesList[index],
                cancelTokenSource.token,
                (event: any) => {
                  filesProgress[index] = Math.round(
                    (100 * event.loaded) / event.total
                  );
                  setFilesListProgress([
                    ...filesListProgress,
                    ...filesProgress,
                  ]);
                }
              ).then((uploadResponse: any) => {
                if (uploadResponse === "canceled") {
                  console.log(`${file.name} canceled`);
                  filesSavedOrCanceled.push(file);
                  filesCanceled.push(file);
                  setFilesListCanceled([...filesCanceled]);
                  if (
                    filesUploaded.length + filesCanceled.length ===
                    filesList.length
                  ) {
                    saveCommentWithFiles(filesUploaded);
                  }
                  return;
                }
                const type = getFileInfo(file.type).fileType;
                const name = file.name.split("." + type)[0];
                const size = `${(file.size / 1024 / 1000).toFixed(2)} MB`;
                const payload = { name, type, size, url: file.url };

                filesUploaded.push(payload);
                if (
                  filesUploaded.length + filesCanceled.length ===
                  filesList.length
                ) {
                  saveCommentWithFiles(filesUploaded);
                }
              });
            });
        });
      } else {
        saveCommentWithFiles();
      }
    }
  };

  async function saveCommentWithFiles(files: any[] = []) {
    await casesService.addCommunicationComment(
      caseDetails.secretKey,
      caseDetails.statusId,
      files,
      comment
    );
    resetData();
  }

  const resetData = () => {
    setSaving(false);
    props.onUploadingFiles(false);
    setComment("");
    setFilesList([]);
    setFilesListProgress([]);
    fetchCaseComments(caseDetails.secretKey);
  };

  async function fetchCaseComments(id: string) {
    setCommunicationsLoading(true);
    setCaseComments(dummyComments);
    const caseComments = await casesService.caseComments(id);
    setTimeout(() => {
      setCaseComments(caseComments);
      setCommunicationsLoading(false);
    }, 100);
  }

  const clickUpload = () => {
    if (!saving) {
      const element: any = inputFileRef.current;
      element.click();
    }
  };

  async function getImagePreview(file: File) {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const src = reader.result;
        resolve(src);
      };
      reader.readAsDataURL(file);
    });
  }

  const validateFiles = async (files: any[]) => {
    const filteredFilesList: any = [];
    const errorsMsg: any[] = [];
    for (const file of files) {
      const { fileType, maxSize } = getFileInfo(file.type);
      const fileName = file.name.split("." + fileType)[0];
      if (file.size > maxSize) {
        errorsMsg.push(
          `<div class='alert-file-name'>
          ${turncate(fileName, 20)}
          </div> 
          is bigger than max 
          ${fileType.toUpperCase()} file size (${maxSize / 1024 / 1000} MB)`
        );
      } else {
        const newFile: any = new File([file], file.name.replace(/[&/\\#, +()$~%'":*?<>{}]/g, '-'), { type: file.type });
        if (fileType.includes("jpg") || fileType.includes("png")) {
          newFile.src = await getImagePreview(newFile);
          filteredFilesList.push(newFile);
        } else {
          filteredFilesList.push(newFile);
        }
      }
    }
    showAlertMsg(errorsMsg);
    setFilesList([...filesList, ...filteredFilesList]);
  };

  useEffect(() => {
    if (!saving && props.droppedFiles && props.droppedFiles.length) {
      validateFiles(props.droppedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.droppedFiles]);

  const onFileChange = async (e: any) => {
    await validateFiles(e.target.files);
    const element: any = inputFileRef.current;
    element.value = "";
  };

  const getFileInfo = (type: string): any => {
    switch (type) {
      case "video/mp4":
        return { fileType: "mp4", maxSize: FileSizes.mp4.maxSize };
      case "image/jpeg":
        return { fileType: "jpg", maxSize: FileSizes.jpg.maxSize };
      case "image/png":
        return { fileType: "png", maxSize: FileSizes.png.maxSize };
      case "application/pdf":
        return { fileType: "pdf", maxSize: FileSizes.pdf.maxSize };
      default:
        break;
    }
  };

  async function showAlertMsg(queues: any[]) {
    const MySwal = withReactContent(Swal);
    for (const message of queues) {
      await MySwal.fire({
        icon: "error",
        title: message,
        ...alertToastConfig,
      });
    }
  }

  const deleteFile = (index: number) => {
    const filesCopy = [...filesList];
    if (filesList[index].cancelTokenSource) {
      filesList[index].cancelTokenSource.cancel();
      filesList[index].canceled = true;
    } else {
      filesCopy.splice(index, 1);
      setFilesList(filesCopy);
    }
  };

  const handleUploadFocus = (event: any) => {
    const element: any = uploadEl.current;
    element.classList.add("active");
  };

  const handleUploadBlur = (event: any) => {
    const element: any = uploadEl.current;
    element.classList.remove("active");
  };

  return (
    <div className="add-comment">
      <div className="row">
        <div className="notes-col">
          <div className="d-flex">
            <div className="info-icon">
              <img src={icons.info} alt="" />
            </div>
            <div className="info-note">
              {t("case.communication-text")}
            </div>
          </div>
        </div>
        <div className="comment-col">
          <div className="w-100">
            <input
              type="file"
              ref={inputFileRef}
              onChange={onFileChange}
              style={{ display: "none" }}
              multiple
              accept={fileTypes}
            />
            <TextField
              onFocus={handleUploadFocus}
              onBlur={handleUploadBlur}
              className="text-area-multiline upload-area timeline-upload w-100"
              id="timeline-file-upload"
              label={t("case.communication-label")}
              multiline
              rows={2}
              variant="filled"
              value={comment}
              onChange={handleChange}
              disabled={saving}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={filesList.length > 0 ? "icons-wrap" : ""}
                  >
                    <AttachFileIcon
                      className="upload-icon mr-3"
                      onClick={clickUpload}
                    />
                    <SendIcon
                      className={
                        !saving &&
                          isValid(comment) &&
                          filesList.length <= maxFiles
                          ? "svg-active"
                          : "svg-grey"
                      }
                      onClick={submit}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <div className="files-preview" ref={uploadEl}>
              {filesList.map((file: any, index: any) => {
                return (
                  !file.canceled && (
                    <div
                      className={
                        "file-preview " +
                        (saving ? "loading" : "") +
                        (filesList.length > 4 && isMobile ? "pt-2" : "")
                      }
                      key={index}
                    >
                      <StyledTooltip title={file.name} placement="bottom">
                        <img
                          src={
                            file.src
                              ? file.src
                              : icons[getFileInfo(file.type).fileType]
                          }
                          alt=""
                        />
                      </StyledTooltip>
                      {filesListProgress[index] !== 100 && (
                        <div
                          className="file-delete"
                          onClick={() => deleteFile(index)}
                        >
                          <ClearIcon />
                        </div>
                      )}
                      {filesListProgress[index] === 100 && (
                        <div className="file-success">
                          <DoneIcon />
                        </div>
                      )}
                      {saving && filesListProgress[index] < 100 && (
                        <div className="file-progress">
                          {filesListProgress[index] > 5 ? (
                            <CircularProgress
                              variant="static"
                              value={filesListProgress[index]}
                            />
                          ) : (
                            <CircularProgress />
                          )}
                        </div>
                      )}
                    </div>
                  )
                );
              })}
            </div>
            <div className="file-upload-counts">
              <div className="d-flex justify-content-end">
                <div
                  className={
                    "input-char-count files-number " +
                    (filesList.length > maxFiles ? "invalid" : "")
                  }
                >
                  {filesList.length - filesListCanceled.length}/{maxFiles}{" "}
                  {t("case.files")}
                </div>
                <div className="input-char-count file-types">
                  (PDF max. 20MB; JPG, PNG max. 8MB; MP4 max. 200MB)
                </div>
              </div>

              <div
                className={
                  "input-char-count comment-chars " +
                  (comment && !isValid(comment) ? "invalid" : "")
                }
              >
                {comment.length}/{maxChar} {t("case.character")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
