import { SessionStorageService } from "../session-storage/session-storage";
import { history } from "react-router-guard";

export class GuardsService {
  private sessionStorageService: SessionStorageService = new SessionStorageService();

  public isCaseActive = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const caseKey = this.sessionStorageService.getData("caseInternalId");
        if (caseKey) {
          return resolve(caseKey);
        } else {
          history.push({
            pathname: `/track`,
          });
          return reject();
        }
      } catch (error) {
        history.push({
          pathname: `/track`,
        });
        return reject();
      }
    });
  };

}
