export class CaseForm {
  public branchId: any = "";
  public originalLandId: any = "";
  public originalAmountId: any = null;
  public originalRelationshipId: any = null;
  public categoryId: any = "";
  public categoryPrefix: string = "";
  public originalDescription: string = "";
  public originalStartDate: any = null;
  public originalEndDate: any = null;
  public originalInvolvedCompany: string = "";
  public originalInvolvedDepartment: string = "";
  public originalInvolvedPersons: string = "";
  public reporterName?: string = "";
  public reporterEmail?: string = "";
  public phoneNumber?: string = "";
  public isAnonymous: Boolean = true;
  public originalCaseOngoing: Boolean = true;
  public branch?: any = null;
  public category?: any = null;
  public amount?: any = null;
  public relation?: any = null;
  public filesList?: any = [];
}
