import React, { useContext, useState } from "react";
import { GlobalContext, HomeContext } from "../../store";
import "./create-case-step-3.component.scoped.scss";
import { Button } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DefaultInputView from "../default-input-view/default-input-view.component";
import { ConfirmDialog } from "../../dialogs/confirm-dialog/confirm-dialog";
import { CasesService } from "../../services/cases/cases.service";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
import { FileSizes } from "../../statics/file-sizes";
import CircularProgress from "@material-ui/core/CircularProgress";
import { icons } from "../../statics/icons";

export default function CreateCaseStep3() {
  const {
    setActiveStep,
    caseForm,
    setCaseSecretKey,
    lands,
    setCaseForm,
  } = useContext(HomeContext);
  const { translation } = useContext(GlobalContext);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const isLargeTablet = useMediaQuery("(max-width:992px)");
  const casesService = new CasesService();
  const [saving, setSaving] = useState(false);
  const [filesLoading, setFilesLoading] = useState(false);
  const textPoints = [
    translation.check["dialog-confirm-case-point-one"],
    translation.check["dialog-confirm-case-point-two"],
  ];
  const land = lands.find((el: any) => el.id === caseForm.originalLandId);

  const handleClose = async (value: any) => {
    setTimeout(() => {
      setShowConfirmModal(false);
    }, 0);
    if (value) {
      setSaving(true);
      const payload = prepareData();
      const caseKey = await casesService.createCase(payload);
      if (caseKey && caseKey.secretKey) {
        setCaseSecretKey(caseKey.secretKey);
      }
      if (caseForm.filesList.length) {
        await uploadFiles(caseKey.secretKey, caseKey.id);
      } else {
        setActiveStep(3);
      }
      setSaving(false);
      // if (saving && filesLoading) {

      // }
    }
  };

  const getFileInfo = (type: string): any => {
    switch (type) {
      case "video/mp4":
        return { fileType: "mp4", maxSize: FileSizes.mp4.maxSize };
      case "audio/mp3":
        return { fileType: "mp3", maxSize: FileSizes.mp3.maxSize };
      case "image/jpeg":
        return { fileType: "jpg", maxSize: FileSizes.jpg.maxSize };
      case "image/png":
        return { fileType: "png", maxSize: FileSizes.png.maxSize };
      case "application/pdf":
        return { fileType: "pdf", maxSize: FileSizes.pdf.maxSize };
      default:
        break;
    }
  };

  const uploadFiles = (secretKey: any, caseId: any) => {
    const filesUploaded: any[] = [];
    const filesCanceled: any[] = [];
    if (caseForm.filesList.length) {
      caseForm.filesList.forEach((file: any, index: number) => {
        casesService
          .requestSignedUrl(file.name, getFileInfo(file.type).fileType, caseId)
          .then((response: any) => {
            setFilesLoading(true);
            const { signedRequest, url } = response;
            const cancelTokenSource = axios.CancelToken.source();
            caseForm.filesList[index].cancelTokenSource = cancelTokenSource;
            caseForm.filesList[index].url = url;
            casesService
              .uploadToSignedURL(
                signedRequest,
                caseForm.filesList[index],
                cancelTokenSource.token,
                (event: any) => {
                  console.log(event);
                }
              )
              .then((uploadResponse: any) => {
                if (uploadResponse === "canceled") {
                  // console.log(`${file.name} canceled`);
                  // filesSavedOrCanceled.push(file);
                  filesCanceled.push(file);
                  // setFilesListCanceled([...filesCanceled]);
                  // if (
                  //   filesUploaded.length + filesCanceled.length ===
                  //   filesList.length
                  // ) {
                  //   saveCommentWithFiles(filesUploaded);
                  // }
                  return;
                }
                const type = getFileInfo(file.type).fileType;
                const name = file.name.split("." + type)[0];
                const size = `${(file.size / 1024 / 1000).toFixed(2)} MB`;
                const payload = { name, type, size, url: file.url };

                filesUploaded.push(payload);
                if (
                  filesUploaded.length + filesCanceled.length ===
                  caseForm.filesList.length
                ) {
                  saveCommentWithFiles(filesUploaded, secretKey);
                  setFilesLoading(false);
                  setActiveStep(3);
                }
              });
          });
      });
    }
  };

  async function saveCommentWithFiles(files: any[] = [], secretKey: any) {
    await casesService.addCommunicationComment(
      secretKey,
      1,
      files,
      "reporter uploaded file"
    );
  }

  const prepareData = () => {
    const data: any = { ...caseForm };
    data.isAnonymous = data.isAnonymous ? 1 : 0;
    data.originalCaseOngoing = data.originalCaseOngoing ? 1 : 0;
    data.originalInvolvedPersons = data.originalInvolvedPersons.replace(
      /,/g,
      ", "
    );
    data.originalInvolvedDepartment = data.originalInvolvedDepartment.replace(
      /,/g,
      ", "
    );
    data.originalInvolvedCompany = data.originalInvolvedCompany.replace(
      /,/g,
      ", "
    );
    data.link = window.location.origin;

    if (data.originalStartDate) {
      data.originalStartDate = data.originalStartDate.format(
        "YYYY-MM-DD hh:mm:ss"
      );
    }
    if (data.originalEndDate) {
      data.originalEndDate = data.originalEndDate.format("YYYY-MM-DD hh:mm:ss");
    }

    if (caseForm.isAnonymous) {
      delete data.reporterEmail;
      delete data.reporterName;
      setCaseForm({ ...caseForm, reporterEmail: "", reporterName: "" });
    }

    delete data.branch;
    delete data.category;
    delete data.amount;
    delete data.relation;
    delete data.filesList;
    return data;
  };

  return (
    <div className="theme-container step-container">
      <div className="row no-gutters step-content">
        <div className="col-sm-12 col-md-5 col-lg-4">
          <section className="section">
            <h3 className="section-title">
              {translation.check["check-title"]}
            </h3>
            <p
              className="section-text"
              dangerouslySetInnerHTML={{
                __html: translation.check["check-text"],
              }}
            ></p>
          </section>
        </div>
        <div className="col-sm-12 col-md-7 col-lg-8 card-wrapper">
          <section className="wrapper ">
            <div className="section-title">
              {translation.check["check-information"]}
            </div>
            <div className={`row ${isLargeTablet ? "" : "w-100"}`}>
              <div className="col-lg-6 col-sm-12 input-view">
                <DefaultInputView
                  label={translation.check["company"]}
                  value={caseForm.branch.name}
                />
              </div>
              <div className="col-lg-6 col-sm-12 input-view">
                <DefaultInputView
                  label={translation.check["country"]}
                  value={land.name}
                />
              </div>

              <div className="col-lg-12 col-sm-12 input-view">
                <DefaultInputView
                  label={translation.check["category"]}
                  value={caseForm.category.name}
                />
              </div>

              {!caseForm.isAnonymous && (
                <>
                  <div className="col-lg-6 col-sm-12 input-view">
                    <DefaultInputView
                      label={translation.check["name-username"]}
                      value={caseForm.reporterName}
                    />
                  </div>
                  {caseForm.reporterEmail && (
                    <div className="col-lg-6 col-sm-12 input-view">
                      <DefaultInputView
                        label={translation.check["email-address"]}
                        value={caseForm.reporterEmail}
                      />
                    </div>
                  )}
                </>
              )}
              <div className="col-12 input-view">
                <DefaultInputView
                  label={translation.check["description"]}
                  value={caseForm.originalDescription}
                />
              </div>

              {caseForm.filesList.length !== 0 && (
                <div className="files-wrap mb-3 pl-3">
                  {caseForm.filesList.map((file: any) => {
                    return (
                      <>
                        <div className="timeline-file">
                          <div className="file-container">
                            <div className="file-icon">
                              <img
                                src={icons[file.type.split("/")[1]]}
                                alt=""
                              />
                            </div>
                            <div className="file-info d-flex flex-column">
                              <div className="file-name trim">{file.name}</div>
                              <div className="file-size">
                                {(file.size / (1024 * 1000))
                                  .toString()
                                  .substring(0, 5)}{" "}
                                MB
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              )}

              {caseForm.originalStartDate && (
                <div className="col-lg-6 col-sm-12 input-view">
                  <DefaultInputView
                    label={translation.check["start-date"]}
                    value={caseForm.originalStartDate.format("DD.MM.YYYY")}
                  />
                </div>
              )}

              {caseForm.originalEndDate && (
                <div className="col-lg-6 col-sm-12 input-view">
                  <DefaultInputView
                    label={translation.check["end-date"]}
                    value={caseForm.originalEndDate.format("DD.MM.YYYY")}
                  />
                </div>
              )}

              {caseForm.originalInvolvedPersons && (
                <div className="col-12 input-view">
                  <DefaultInputView
                    label={translation.check["involved-people"]}
                    value={caseForm.originalInvolvedPersons.replace(/,/g, ", ")}
                  />
                </div>
              )}

              {caseForm.originalInvolvedDepartment && (
                <div className="col-lg-6 col-sm-12 input-view">
                  <DefaultInputView
                    label={translation.check["involved-department"]}
                    value={caseForm.originalInvolvedDepartment.replace(
                      /,/g,
                      ", "
                    )}
                  />
                </div>
              )}
              {caseForm.originalInvolvedCompany && (
                <div className="col-lg-6 col-sm-12 input-view">
                  <DefaultInputView
                    label={translation.check["involved-company"]}
                    value={caseForm.originalInvolvedCompany.replace(/,/g, ", ")}
                  />
                </div>
              )}
              {caseForm.amount && (
                <div className="col-lg-6 col-sm-12 input-view">
                  <DefaultInputView
                    label={translation.check["amount-damage"]}
                    value={caseForm.amount.value}
                  />
                </div>
              )}
              {caseForm.relation && (
                <div className="col-lg-6 col-sm-12 input-view">
                  <DefaultInputView
                    label={translation.check["relation"]}
                    value={caseForm.relation.name}
                  />
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      <div className="footer d-flex flex-row justify-content-end align-items-end">
        <Button
          className="btn-secondary-theme btn-prev"
          variant="outlined"
          color="primary"
          onClick={setActiveStep.bind(null, 1)}
        >
          {translation.check["back"]}
        </Button>
        <Button
          className="btn-primary-theme btn-next"
          variant="contained"
          color="primary"
          onClick={setShowConfirmModal.bind(null, true)}
        >
          {filesLoading || saving ? (
            <CircularProgress color="inherit" />
          ) : (
            <>
              <ArrowForwardIcon /> {translation.check["finish"]}
            </>
          )}
        </Button>
      </div>
      {showConfirmModal && (
        <ConfirmDialog
          show={showConfirmModal}
          showCheck={true}
          onClose={handleClose}
          dialogTitle={translation.check["dialog-confirm-case-title"]}
          dialogBody={translation.check["dialog-confirm-case-text"]}
          dialogExtraText={translation.check["dialog-confirm-case-footer"]}
          dialogPointsText={textPoints}
          confirmBtnText={translation.check["dialog-confirm-case-approve"]}
          cancelBtn={true}
          dialogClassName="confirm-create-case-modal"
        />
      )}
    </div>
  );
}
