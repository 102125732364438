import React, { useContext } from "react";
import "./case-header-sticky.component.scoped.scss";
import { CaseContext } from "../../store";
import Skeleton from "@material-ui/lab/Skeleton";
import CaseTabsComponent from "../case-tabs/case-tabs.component";

export default function CaseHeaderStickyComponent(props: any) {
  const { loading } = useContext(CaseContext);

  return (
    <div className={"case-container case-header w-100"}>
      <div className="row no-gutters sticky-buttons-wrapper">
        <div className="left-header">
          {loading ? (
            <Skeleton variant="text" height={35} />
          ) : (
            <div className="tabs-sticky">
              <CaseTabsComponent />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
