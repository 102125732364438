import React from "react";
import "./case-tab-content.component.scoped.scss";
import { CaseContext } from "../../store";
import { DescriptionTabComponent } from "../description-tab/description-tab.component";
import { CommunicationTabComponent } from "../communication-tab/communication-tab.component";

export default function CaseTabContentComponent() {
  const { activeTab } = React.useContext(CaseContext);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tab-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  return (
    <div className="w-100">
      <TabPanel value={activeTab} index={0}>
        <DescriptionTabComponent />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <CommunicationTabComponent />
      </TabPanel>
    </div>
  );
}
