export type Icons = {
  [key: string]: string;
};

export const icons: Icons = {
  userImg: "/icons/profile.svg",
  usersImg: "/icons/users.svg",
  usersImgWhite: "/icons/user-white.svg",
  userImgLight: "/icons/profile-light.svg",
  reporterImg: "/icons/reporter.svg",

  amountOfDamage: "/icons/category/amount-of-damage.svg",
  company: "/icons/category/company.svg",
  date: "/icons/category/date.svg",
  relation: "/icons/category/relation.svg",
  involvedDepartments: "/icons/category/involved-departments.svg",
  involvedPeople: "/icons/category/involved-people.svg",
  category: "/icons/category/category.svg",
  country: "/icons/category/country.svg",
  labels: "/icons/category/labels.svg",
  result: "/icons/category/result.svg",

  received: "/icons/case-status/received.svg",
  inProgress: "/icons/case-status/in-progress.svg",
  completed: "/icons/case-status/completed.svg",
  review: "/icons/case-status/review.svg",

  receivedOrange: "/icons/case-status/received-orange.svg",
  inProgressOrange: "/icons/case-status/in-progress-orange.svg",
  completedOrange: "/icons/case-status/completed-orange.svg",
  reviewOrange: "/icons/case-status/review-orange.svg",

  completedGrey: "/icons/case-status/completed-grey.svg",

  jpg: "/icons/file-formats/jpg.svg",
  jpeg: "/icons/file-formats/jpg.svg",
  mp3: "/icons/file-formats/mp3.svg",
  mp4: "/icons/file-formats/mp4.svg",
  pdf: "/icons/file-formats/pdf.svg",
  png: "/icons/file-formats/png.svg",

  de: "/icons/language/de.svg",
  en: "/icons/language/en.svg",

  bauhausLogo: "/icons/logos/logo-bauhaus.svg",
  hornbachLogo: "/icons/logos/logo-hornbach.svg",
  obiLogo: "/icons/logos/logo-obi.svg",

  logo: "/icons/logos/compliance2b.svg",
  add: "/icons/add.svg",
  addLight: "/icons/add-light.svg",
  alert: "/icons/alert.svg",
  arrowForward: "/icons/arrow-forward.svg",
  check: "/icons/check.svg",
  checkWhite: "/icons/check-white.svg",
  chevronDown: "/icons/chevron-down.svg",
  chevronUp: "/icons/chevron-up.svg",
  clock: "/icons/clock.svg",
  close: "/icons/close.svg",
  comment: "/icons/comment.svg",
  commentOrange: "/icons/comment-orange.svg",
  datePicker: "/icons/date-picker.svg",
  download: "/icons/download.svg",
  downloadOrange: "/icons/download-orange.svg",
  downloadLight: "/icons/download-light.svg",
  edit: "/icons/edit.svg",
  firstEditor: "/icons/first-editor.svg",
  help: "/icons/help.svg",
  info: "/icons/info.svg",
  infoDark: "/icons/info-dark.svg",
  more: "/icons/more-options.svg",
  notifications: "/icons/notifications.svg",
  off: "/icons/off.svg",
  offLight: "/icons/off-light.svg",
  otherEditors: "/icons/other-editors.svg",
  password: "/icons/password.svg",
  search: "/icons/search.svg",
  send: "/icons/send.svg",
  settings: "/icons/settings.svg",
  settingsLight: "/icons/settings-light.svg",
  thirdParty: "/icons/third-party.svg",
  user: "/icons/user.svg",
  view: "/icons/view.svg",
  delete: "/icons/delete.svg",
  trackReport: "/icons/track-report.svg",
  trackReportLight: "/icons/track-report-light.svg",
  copy: "/icons/copy.svg",
};
