import React from "react";
import { icons } from "../../statics/icons";
import "./timeline-file.component.scoped.scss";

export const TimelineFileComponent = (props: any) => {
  const downloadFile = () => {
    const a = document.createElement("a");
    a.href = props.url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="timeline-file">
      <div className="file-container">
        <div className="file-icon" onClick={downloadFile}>
          <img src={icons[props.type]} alt="" />
        </div>
        <div className="file-info d-flex flex-column">
          <div className="file-name trim" onClick={downloadFile}>{props.name}</div>
          <div className="file-size">{props.size}</div>
        </div>
      </div>
      <div className="download-icon" onClick={downloadFile}>
        <img src={icons.downloadLight} alt="" />
      </div>
    </div>
  );
};
