import React from "react"
import "./description-tab.component.scoped.scss"
import { useTranslation } from "react-i18next"
import { CaseContext } from "../../store"
import { CardComponent } from "../card/card.component"
import { icons } from "../../statics/icons"
import Skeleton from "@material-ui/lab/Skeleton"
import { sanitize } from "../../helpers"
import { CardInfoComponent } from "../card-info/card-info.component"
import { caseDetailsInfo } from "../../statics/case-details-info"
import { caseReporterInfo } from "../../statics/case-reporter-details"

export const DescriptionTabComponent = (props: any) => {
  const { caseDetails, loading } = React.useContext(CaseContext)
  const [t] = useTranslation()

  return (
    <div className="case-container description-tab">
      <div className="description-tab-wrapper ">
        <div className="card-wrapper">
          <div className="desc-card">
            <CardComponent
              icon={icons.trackReport}
              title={t("case.submited-description")}
              loading={loading}
            >
              {loading ? (
                <Skeleton variant="text" height={165} />
              ) : (
                <div
                  className="desc-body"
                  dangerouslySetInnerHTML={sanitize(caseDetails.originalDescription)}
                />
              )}
            </CardComponent>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="details-card">
            <CardComponent
              icon={icons.infoDark}
              title={t("case.optional-data")}
              loading={loading}
            >
              <div className="details-body">
                {caseDetails.optionalFieldsEmpty && (
                  <div className="no-result">{t("case.no data available")}</div>
                )}
                {!caseDetails.optionalFieldsEmpty && (
                  <div className="row">
                    {caseDetailsInfo.map((item: any, index: any) => {
                      return (
                        (loading || (!loading && caseDetails[item.value])) && (
                          <div className="col-lg-6 col-md-12" key={index}>
                            <CardInfoComponent
                              icon={item.icon}
                              title={item.title}
                              value={caseDetails[item.value]}
                              loading={loading}
                            />
                          </div>
                        )
                      )
                    })}
                  </div>
                )}
              </div>
            </CardComponent>
          </div>
          {(!caseDetails.isAnonymous && caseDetails.reporterActivated === 1) && (
            <div className="details-card mt-2">
              <CardComponent
                icon={icons.infoDark}
                title={t("case.notice-information")}
                loading={loading}>
                <div className="details-body">
                  <div className="row">
                    {caseReporterInfo.map((item: any, index: any) => {
                      return (
                        (loading || (!loading && caseDetails[item.value])) && (
                          <div className="col-lg-6 col-md-12" key={index}>
                            <CardInfoComponent
                              icon={item.icon}
                              title={item.title}
                              value={caseDetails[item.value]}
                              loading={loading}
                            />
                          </div>
                        )
                      )
                    })}
                  </div>
                </div>
              </CardComponent>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
