import { Button } from "@material-ui/core";
import React from "react";

import "./label-round.component.scoped.scss";

export const LabelRoundComponent = (props: any) => {
  const { label, clickable, active } = props;

  return (
    <Button
      variant="outlined"
      disableRipple={clickable ? false : true}
      className={
        "label-round " +
        (clickable ? "clickable " : "") +
        (active ? "active" : "")
      }
    >
      {label}
    </Button>
  );
};
