import React, { useEffect } from 'react';
import { history } from "react-router-guard";
import Footer from '../../components/footer/footer.component';
import HeaderComponent from "../../components/header/header.component";
import HomeContent from '../../components/home-content/home-content.component';
import { showToastMsg } from '../../helpers';
import { CasesService } from '../../services/cases/cases.service';
import { HomeProvider } from '../../store';

export default function HomePage(props: any) {
  const casesService = new CasesService();
  const key = props.match.params.key;

  useEffect(function () {
    activate(key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function activate(key: any) {
    if (key) {
      const activated = await casesService.activateAccount(key);
      showToastMsg('success', activated.message);
      if (activated.message) {
        history.replace("/");
      }
    }
  }

  return (
    <HomeProvider>
      <HeaderComponent />
      <HomeContent />
      <Footer links={true} />
    </HomeProvider>
  );
}