import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { icons } from "../../statics/icons";
import { Button, IconButton } from "@material-ui/core";
import { saveAs } from "file-saver";
import { history } from "react-router-guard";
import { GlobalContext, HomeContext } from "../../store";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import "./create-case-step-4.component.scoped.scss";
import { CaseForm } from "../../types/case-form";
import { SessionStorageService } from "../../services/session-storage/session-storage";
import { jsPDF } from "jspdf";
import { ConfirmDialog } from "../../dialogs/confirm-dialog/confirm-dialog";

export default function CreateCaseStep4() {
  const sessionStorageService: SessionStorageService = new SessionStorageService();
  const { translation, configuration } = useContext(GlobalContext);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [t] = useTranslation();
  const {
    setActiveStep,
    setCaseForm,
    setCaseSecretKey,
    caseSecretKey,
    caseForm,
    lands,
  } = useContext(HomeContext);

  const land = lands.find((el: any) => el.id === caseForm.originalLandId);

  const download = () => {
    const blob = new Blob([caseSecretKey], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, "case-secret-key.txt");
  };

  const downloadPdfFile = () => {
    const doc = new jsPDF("p");
    // set header font-size
    doc.setFontSize(18);
    doc.text(`${caseSecretKey}`, 65, 20);
    doc.text(`${t("PDF.case-secret-key")}`, 72, 30);
    // set body font-size
    doc.setFontSize(15);
    let lineHeight = 40;

    if (configuration.optional_fields_visibility) {
      const person = doc.splitTextToSize(
        `${t(
          "PDF.involved-people"
        )}: ${caseForm.originalInvolvedPersons.replace(/,/g, ", ")}`,
        180
      );
      person.forEach((text: any) => {
        doc.text(text, 10, (lineHeight += 7));
      });

      lineHeight += 3;
      const department = doc.splitTextToSize(
        `${t(
          "PDF.involved-departments"
        )}: ${caseForm.originalInvolvedDepartment.replace(/,/g, ", ")}`,
        180
      );
      department.forEach((text: any) => {
        doc.text(text, 10, (lineHeight += 7));
      });
    }

    doc.text(
      `${t("PDF.category")}: ${caseForm.category.name}`,
      10,
      (lineHeight += 10)
    );
    doc.text(
      `${t("PDF.branch")}: ${caseForm.branch.name}`,
      10,
      (lineHeight += 10)
    );
    if (configuration.country_visibility) {
      doc.text(`${t("PDF.country")}: ${land.name}`, 10, (lineHeight += 10));
    }
    if (configuration.optional_fields_visibility) {
      doc.text(
        `${t("PDF.amount")}: ${caseForm.amount ? caseForm.amount.value : ""}`,
        10,
        (lineHeight += 10)
      );
      doc.text(
        `${t("PDF.ongoing-case")}: ${
          caseForm.originalCaseOngoing ? t("PDF.yes") : t("PDF.no")
        }`,
        10,
        (lineHeight += 10)
      );

      lineHeight += 3;
      const company = doc.splitTextToSize(
        `${t(
          "PDF.involved-company"
        )}: ${caseForm.originalInvolvedCompany.replace(/,/g, ", ")}`,
        180
      );
      company.forEach((text: any) => {
        doc.text(text, 10, (lineHeight += 7));
      });
    }
    doc.text(
      `${t("PDF.anoymous-reporter")}: ${
        caseForm.isAnonymous ? t("PDF.yes") : t("PDF.no")
      }`,
      10,
      (lineHeight += 10)
    );
    doc.text(
      `${t("PDF.reporter-name")}: ${caseForm.reporterName}`,
      10,
      (lineHeight += 10)
    );
    doc.text(
      `${t("PDF.reporter-email")}: ${caseForm.reporterEmail}`,
      10,
      (lineHeight += 10)
    );
    if (configuration.optional_fields_visibility) {
      doc.text(
        `${t("PDF.relation")}: ${
          caseForm.relation ? caseForm.relation.name : ""
        }`,
        10,
        (lineHeight += 10)
      );
      doc.text(
        `${t("PDF.start-date")}: ${
          caseForm.originalStartDate ? caseForm.originalStartDate : ""
        }`,
        10,
        (lineHeight += 10)
      );
      doc.text(
        `${t("PDF.end-date")}: ${
          caseForm.originalEndDate ? caseForm.originalEndDate : ""
        }`,
        10,
        (lineHeight += 10)
      );
    }
    // set line-height for description
    doc.setLineHeightFactor(1.5);
    const description = doc.splitTextToSize(
      `${t("PDF.description")}: ${caseForm.originalDescription}`,
      180
    );
    // add new page with condition
    const pageHeight = doc.internal.pageSize.getHeight();
    description.forEach((text: any) => {
      if (lineHeight + 10 > pageHeight) {
        lineHeight = 10;
        doc.addPage();
      }
      doc.text(text, 10, (lineHeight += 10));
    });
    doc.setProperties({ title: `case-${caseSecretKey}` });
    // doc.save(`case-${caseSecretKey}.pdf`);

    var blobPDF = doc.output("blob");
    const blob = new Blob([blobPDF], {
      // type: "pdf/plain;charset=utf-8",
      type: "application/pdf",
    });
    saveAs(blob, `case-${caseSecretKey}.pdf`);
  };

  const copytoClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(caseSecretKey);
    } else {
      const el = document.createElement("textarea");
      el.value = caseSecretKey;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    }
  };

  const handleClose = async (value: any) => {
    setTimeout(() => {
      setShowConfirmModal(false);
    }, 0);
    if (value) {
      navigateToCaseDetails();
    }
  };

  const navigateToCaseDetails = () => {
    sessionStorageService.setData("caseInternalId", caseSecretKey);
    history.push("/case");
  };

  const navigateToMainPage = () => {
    setActiveStep(0);
    setCaseForm(new CaseForm());
    setCaseSecretKey("");
  };

  return (
    <div className="theme-container step-container">
      <section className="flex-box-center flex-column text-center">
        <div className="sec-title">{translation.download["title"]}</div>
        <div className="sec-text">
          <p
            dangerouslySetInnerHTML={{
              __html: translation.download["text-one"],
            }}
          ></p>
          <p
            dangerouslySetInnerHTML={{
              __html: translation.download["text-two"],
            }}
          ></p>
          <p
            className="warning-text"
            dangerouslySetInnerHTML={{
              __html: translation.download["text-important"],
            }}
          ></p>
        </div>
      </section>

      <div className="ticket-entry mx-auto">
        <div className="wrapper">
          <div className=" d-flex justify-content-center">
            <img src={icons.involvedDepartments} className="icon" alt="" />
            <div className="number-wrapper d-flex flex-column align-items-start">
              <div className="header-text">
                {translation.download["access-data"]}
              </div>
              <div className="d-flex align-items-center">
                <div className="ticket-number">{caseSecretKey}</div>
                <IconButton
                  aria-label="copy"
                  onClick={copytoClipboard}
                  style={{ padding: "5px" }}
                >
                  <img src={icons.copy} alt="" />{" "}
                </IconButton>
              </div>
            </div>
          </div>

          <div className="download-button d-flex justify-content-center mt-5">
            <Button
              className="btn-secondary-theme btn-download"
              variant="outlined"
              color="primary"
              onClick={download}
            >
              {/* <img src={icons.downloadOrange} alt="" /> */}
              <span className="download-icon"></span>{" "}
              {translation.download["download-access-data"]}
            </Button>
          </div>

          <div
            className="download-button d-flex justify-content-center"
            style={{ marginTop: "20px" }}
          >
            <Button
              className="btn-secondary-theme btn-download"
              variant="outlined"
              color="primary"
              onClick={downloadPdfFile}
            >
              <span className="download-icon"></span>
              {translation.download["download-pdf-file"]}
            </Button>
          </div>
        </div>
      </div>
      <div className="footer d-flex justify-content-end align-items-end">
        <Button
          className="btn-secondary-theme btn-prev"
          variant="outlined"
          color="primary"
          onClick={navigateToMainPage}
        >
          <CloseIcon />
          {translation.download["close"]}
        </Button>
        <Button
          className="btn-primary-theme btn-next"
          variant="contained"
          color="primary"
          onClick={(e) => setShowConfirmModal(true)}
        >
          <ArrowForwardIcon />
          {translation.download["open-case"]}
        </Button>
      </div>
      {showConfirmModal && (
        <ConfirmDialog
          show={showConfirmModal}
          showCheck={true}
          onClose={handleClose}
          dialogTitle={translation.download["dialog-open-case-title"]}
          dialogBody={translation.download["dialog-open-case-text"]}
          confirmBtnText={translation.download["dialog-open-case-yes"]}
          cancelBtn={true}
          fontSize={17}
          dialogClassName="confirm-create-case-modal"
        />
      )}
    </div>
  );
}
