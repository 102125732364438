import packageJson from '../../package.json';
const URL = window.location.host.split('.');
const subDomain = URL.shift();
const domain = URL.join('.');

export const grcExpertsbEnvironmentConfig = {
    serverUrl: `https://${subDomain}-api.${domain}/`,
    clientPrefix: "MOW",
    storagePrefix: "live",
    appVersion: packageJson.version
};
