import React from "react"
import Skeleton from "@material-ui/lab/Skeleton"
import "./card-info.component.scoped.scss"
import { useTranslation } from "react-i18next"
import { LabelRoundComponent } from "../label-round/label-round.component"

export const CardInfoComponent = (props: any) => {
  const { icon, title, value, loading } = props
  const [t] = useTranslation()

  return (
    <div className="card-info">
      {loading ? (
        <Skeleton
          variant="circle"
          height={25}
          width={25}
          style={{ marginRight: "15px" }}
        />
      ) : (
        <div className="card-info-icon">
          <img src={icon} alt="" />
        </div>
      )}
      <div className="card-info-body">
        {loading ? (
          <Skeleton
            variant="text"
            height={15}
            style={{ marginBottom: "5px" }}
          />
        ) : (
          <div className="card-info-title">{t(title)}</div>
        )}
        {loading ? (
          <Skeleton variant="text" height={15} />
        ) : title === "labels" && value && value.length === 0 ? (
          <div className="card-info-text dimmed">{t("no labels added")}</div>
        ) : (
          title !== "labels" &&
          title !== "involved company" &&
          title !== "involved people" &&
          title !== "involved department" && (
            <div className="card-info-text">{value}</div>
          )
        )}
        {!loading && title === "labels" && (
          <div className="card-info-labels">
            {Array.isArray(value) &&
              value.map((label: any, index: any) => {
                return (
                  <div className="card-label" key={index}>
                    <LabelRoundComponent label={label.name} key={index} />
                  </div>
                )
              })}
          </div>
        )}

        {!loading &&
          (title === "involved company" ||
            title === "involved people" ||
            title === "involved department") && (
            <div className="card-info-labels">
              {Array.isArray(value.split(",")) &&
                value.split(",").map((label: any, index: any) => {
                  return (
                    <div className="card-label" key={index}>
                      <LabelRoundComponent label={label} />
                    </div>
                  )
                })}
            </div>
          )}
      </div>
    </div>
  )
}
