import axios from "axios";
import { config } from "../../config";

export class ConfigurationService {

    public getConfiguration = async (): Promise<any> => {
        try {
            const { serverUrl } = config;
            const response = await axios.get(serverUrl + "configuration");
            return response.data;
        } catch (err) {
            return [];
        }
    };
}
