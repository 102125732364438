import { LangService } from "../lang/lang.service";
import "moment/locale/de";
import { Case } from "../../types/case";
import { Status } from "../../types/status";
import { statusIcons } from "../../statics/status-icons";
import { formatDate } from "../../helpers";

export class MapperService {
  private langService: LangService = new LangService();

  public mapStatuses = (statuses: Status[]) => {
    return statuses.map((status: Status, index: number) => {
      status = { ...new Status(), ...status };
      status.icon = statusIcons[index].white;
      status.iconOrange = statusIcons[index].orange;
      status.iconGrey = statusIcons[index].grey;
      return status;
    });
  };

  public mapCase = (res: Case, statuses: Status[], logoUrl: string) => {
    const mappedCase: Case = { ...res };
    const mappedtimelineStatuses: Status[] = [...statuses];

    if (logoUrl) {
      mappedCase.clientIcon = logoUrl;
    } else if (window.location.host.includes('grc-experts.report')) {
      mappedCase.clientIcon = "/fav/grc-expert.png"
    } else if (window.location.host.includes('compliance2b.com')) {
      mappedCase.clientIcon = "/fav/moweex.png";
    }

    let isStatusAfterActiveStatus = false;
    for (let index = 0; index < statuses.length; index++) {
      const status = mappedtimelineStatuses[index];
      status.expand = false;
      status.dimmed = false;
      if (mappedCase.status === status.name) {
        mappedCase.statusColor = status.color;
        mappedCase.statusId = status.id;
        status.expand = true;
        isStatusAfterActiveStatus = true;
        continue;
      }
      if (isStatusAfterActiveStatus) {
        status.dimmed = true;
      }
    }
    // check optional fields
    mappedCase.optionalFieldsEmpty = true;
    if (
      mappedCase.originalInvolvedPersons ||
      mappedCase.originalAmount ||
      mappedCase.originalInvolvedDepartment ||
      (mappedCase.originalStartDate && mappedCase.originalEndDate) ||
      mappedCase.originalInvolvedCompany ||
      mappedCase.originalRelationship
    ) {
      mappedCase.optionalFieldsEmpty = false;
    }
    if (mappedCase.originalEndDate && mappedCase.originalStartDate) {
      mappedCase.duration = `${formatDate(
        mappedCase.originalStartDate,
        "dddd, DD.MM.YYYY"
      )} - ${formatDate(mappedCase.originalEndDate, "dddd, DD.MM.YYYY")}`;
    }
    return { mappedCase, mappedtimelineStatuses };
  };

  public mapTimelineStatus = (caseDetails: Case, statuses: Status[]) => {
    const mappedCase: Case = { ...caseDetails };
    const mappedtimelineStatuses: Status[] = [...statuses];
    let isStatusAfterActiveStatus = false;
    for (let index = 0; index < statuses.length; index++) {
      const status = mappedtimelineStatuses[index];
      status.expand = false;
      status.dimmed = false;
      if (mappedCase.status === status.name) {
        status.expand = true;
        isStatusAfterActiveStatus = true;
        continue;
      }
      if (isStatusAfterActiveStatus) {
        status.dimmed = true;
      }
    }
    return mappedtimelineStatuses;
  };
}
