import moment from "moment";
import "moment/locale/de";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { LangService } from "../services/lang/lang.service";
import { alertToastConfig } from "../statics/alert-config";
const langService: LangService = new LangService();
const sanitizeHtml = require("sanitize-html");

export const formatDate = (date: any, format: string) => {
  if (date && format) {
    const momentDate = moment(new Date(date));
    const lang = langService.getCurrentLang();
    moment.locale(lang);
    const formatedDate = momentDate.format(format);
    return formatedDate;
  }
};

export const deleteEmptyProp = (obj: any = {}) => {
  const object = { ...obj };
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const element = object[key];
      if (!element) {
        delete obj[key];
      }
    }
  }
  return obj;
};

export const limit = (text: string, length: number) => text?.substr(0, length);

export const turncate = (text: string = "", length: number) => {
  if (text.length > length) {
    return text.substr(0, length) + "...";
  } else {
    return text.substr(0, length);
  }
};

export const sanitize = (dirty: any) => ({
  __html: sanitizeHtml(dirty, {
    allowedTags: ["b", "i", "em", "strong", "u", "p", "br"],
  }),
});

export const replaceTags = (text: string = "") => {
  return text.replace(/(<([^>]+)>)/gi, "");
};

// deep clone (array of objects - array of arrays)
export const deepClone = (arr: any[] = []): any[] =>
  arr.map((item) =>
    Array.isArray(item)
      ? deepClone(item)
      : typeof item === "object"
      ? { ...item }
      : item
  );

export const isAllTextSpaces = (text: string = "") => {
  const regex = /^\s+$/;
  // check if user write spaces at first
  if (regex.test(text)) {
    return true;
  }
  return false;
};

export const showToastMsg = (icon: string, title: string) => {
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    icon,
    title,
    ...alertToastConfig,
  });
};
