import React from 'react';
import ReactDOM from 'react-dom';
import './styles/styles.scss';
import './i18n'
import App from './app.component';
import * as serviceWorker from './serviceWorker';
import { setupInterceptors } from './interceptors/auth-intercept';

setupInterceptors();

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

serviceWorker.unregister();
