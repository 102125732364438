import React from "react";
import "./server-message.component.scoped.scss";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';

export const ServerMessage = (props: any) => {
    const [t] = useTranslation();

    return (
        <>
            <div className="fire-alarm_alarms firealarm-critsits">
                <div className="fire-alarm_alarm fire-alarm_critsit">
                    <div className="fire-alarm_alarm-inner">
                        <div className="fire-alarm_alarm-body">
                            <h2>{t('server message title')}</h2>
                            <p>{t('server message description')}</p>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="fire-alarm_alarm-close-button"
                        onClick={props.close}>
                        <CloseIcon />
                    </button>
                </div>
            </div>
        </>
    );
};
