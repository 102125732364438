export class Status {
  public id: string = "";
  public name: string = "";
  public color: string = "";
  public date?: string = "";
  public icon?: string = ""; 
  public iconOrange?: string = "";
  public iconGrey?: string = "";
  public expand?: boolean = false;
  public showAll?: boolean = false;
  public dimmed?: boolean = false;
  public firstAvailableStatus?: Status;
  public secondAvailableStatus?: Status;
}