import React, { useState, useContext, useRef, useEffect } from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
  Tooltip,
  withStyles,
  Switch,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import MicIcon from "@material-ui/icons/Mic";
import MicNoneIcon from "@material-ui/icons/MicNone";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Button } from "@material-ui/core";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { emailRegex } from "../../statics/validators";
import moment, { Moment } from "moment";
import "moment/locale/de";
import MomentUtils from "@date-io/moment";
import { icons } from "../../statics/icons";
import { GlobalContext, HomeContext } from "../../store";
import "./create-case-step-2.component.scoped.scss";
import { LabelRoundComponent } from "../label-round/label-round.component";
import { LangService } from "../../services/lang/lang.service";
import { isAllTextSpaces, turncate } from "../../helpers";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { alertToastConfig } from "../../statics/alert-config";
import { FileSizes } from "../../statics/file-sizes";
import ClearIcon from "@material-ui/icons/Clear";
import { fileTypes } from "../../statics/file-types";
import MicRecorder from "mic-recorder-to-mp3";

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f2af1d",
    color: "#f5f6f6",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

export default function CreateCaseStep2(props: any) {
  const langService: LangService = new LangService();
  const { translation, configuration } = useContext(GlobalContext);
  const removeEmojiRegex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
  const locale = langService.getCurrentLang();
  moment.locale(locale);
  const maxChar = 2500;
  const {
    setActiveStep,
    caseForm,
    setCaseForm,
    amounts,
    relations,
    categories,
  } = useContext(HomeContext);
  const [startDate, setStartDate] = useState(caseForm.originalStartDate);
  const [endDate, setEndDate] = useState(caseForm.originalEndDate);
  const [datesInvalid, setDatesInvalid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [involvedCompanyError, setInvolvedCompanyError] = useState(false);
  const [involvedPersonError, setInvolvedPersonError] = useState(false);
  const [involvedDepartmentError, setInvolvedDepartmentError] = useState(false);

  const maxFiles = 5;
  const inputFileRef = useRef(null);
  const uploadEl = useRef(null);
  const isMobile = useMediaQuery("(max-width:576px)");

  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState("");
  const [Mp3Recorder, setMp3Recorder] = useState(
    new MicRecorder({ bitRate: 128 })
  );

  useEffect(() => {
    setMp3Recorder(new MicRecorder({ bitRate: 128 }));
  }, []);

  const startRecording = () => {
    Mp3Recorder.start()
      .then(() => {
        setIsRecording(true);
      })
      .catch((e: any) => console.error(e));
  };

  const stopRecording = async () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(async ([buffer, blob]: any) => {
        const file = new File(
          buffer,
          `Whistleblower_recording_${new Date()
            .toJSON()
            .replace(/ /g, "_")}.mp3`,
          {
            type: blob.type,
            lastModified: Date.now(),
          }
        );
        const blobUrl = URL.createObjectURL(blob);
        await validateFiles([file]);
        setBlobURL(blobUrl);
        setIsRecording(false);
      })
      .catch((e: any) => console.log(e));
  };

  const clickUpload = () => {
    const element: any = inputFileRef.current;
    element.click();
  };

  async function getImagePreview(file: File) {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const src = reader.result;
        resolve(src);
      };
      reader.readAsDataURL(file);
    });
  }

  const validateFiles = async (files: any[]) => {
    const filteredFilesList: any = [];
    const errorsMsg: any[] = [];
    for (const file of files) {
      const { fileType, maxSize } = getFileInfo(file.type);

      const fileName = file.name.split("." + fileType)[0];
      if (file.size > maxSize) {
        errorsMsg.push(
          `<div class='alert-file-name'>${turncate(
            fileName,
            20
          )}</div> is bigger than max ${fileType.toUpperCase()} file size (${
            maxSize / 1024 / 1000
          } MB)`
        );
      } else {
        const newFile: any = new File(
          [file],
          file.name.replace(/[&/\\#, +()$~%'":*?<>{}]/g, "-"),
          { type: file.type }
        );
        if (fileType.includes("jpg") || fileType.includes("png")) {
          newFile.src = await getImagePreview(newFile);
          filteredFilesList.push(newFile);
        } else {
          filteredFilesList.push(newFile);
        }
      }
    }
    showAlertMsg(errorsMsg);
    setCaseForm({
      ...caseForm,
      filesList: [...caseForm.filesList, ...filteredFilesList],
    });
  };

  useEffect(() => {
    if (props.droppedFiles && props.droppedFiles.length) {
      validateFiles(props.droppedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.droppedFiles]);

  const onFileChange = async (e: any) => {
    await validateFiles(e.target.files);
  };

  const getFileInfo = (type: string): any => {
    switch (type) {
      case "video/mp4":
        return { fileType: "mp4", maxSize: FileSizes.mp4.maxSize };
      case "audio/mp3":
        return { fileType: "mp3", maxSize: FileSizes.mp3.maxSize };
      case "image/jpeg":
        return { fileType: "jpg", maxSize: FileSizes.jpg.maxSize };
      case "image/png":
        return { fileType: "png", maxSize: FileSizes.png.maxSize };
      case "application/pdf":
        return { fileType: "pdf", maxSize: FileSizes.pdf.maxSize };
      default:
        break;
    }
  };

  async function showAlertMsg(queues: any[]) {
    const MySwal = withReactContent(Swal);
    for (const message of queues) {
      await MySwal.fire({
        icon: "error",
        title: message,
        ...alertToastConfig,
      });
    }
  }

  const deleteFile = (index: number) => {
    const filesCopy = [...caseForm.filesList];
    if (caseForm.filesList[index].cancelTokenSource) {
      caseForm.filesList[index].cancelTokenSource.cancel();
      caseForm.filesList[index].canceled = true;
    } else {
      filesCopy.splice(index, 1);
      setCaseForm({ ...caseForm, filesList: filesCopy });
    }
  };

  const isValidDescription = (text: string = "") => {
    return text.length > 0 && text.length <= maxChar;
  };

  const handleAddPersonClick = (chip: string) => {
    setInvolvedPersonError(false);
    if (chip.length > 50) return;
    const person = handleChipInput(chip);
    if (!person) return;
    const originalInvolvedPersonsCopy = caseForm.originalInvolvedPersons.length
      ? caseForm.originalInvolvedPersons.split(",")
      : [];
    originalInvolvedPersonsCopy.push(person);
    setCaseForm({
      ...caseForm,
      originalInvolvedPersons: originalInvolvedPersonsCopy.toString(),
    });
  };

  const handlePersonDelete = (chip: string) => {
    const newOriginalInvolvedPersons = caseForm.originalInvolvedPersons
      .split(",")
      .filter((item: string) => item !== chip)
      .join();
    setCaseForm({
      ...caseForm,
      originalInvolvedPersons: newOriginalInvolvedPersons,
    });
  };

  const handleAddDepartmentClick = (chip: string) => {
    setInvolvedDepartmentError(false);
    if (chip.length > 50) return;
    const department = handleChipInput(chip);
    if (!department) return;
    const originalInvolvedDepartmentsCopy = caseForm.originalInvolvedDepartment
      .length
      ? caseForm.originalInvolvedDepartment.split(",")
      : [];
    originalInvolvedDepartmentsCopy.push(department);
    setCaseForm({
      ...caseForm,
      originalInvolvedDepartment: originalInvolvedDepartmentsCopy.toString(),
    });
  };

  const handleDepartmentDelete = (chip: string) => {
    const newOriginalInvolvedDepartments = caseForm.originalInvolvedDepartment
      .split(",")
      .filter((item: string) => item !== chip)
      .join();
    setCaseForm({
      ...caseForm,
      originalInvolvedDepartment: newOriginalInvolvedDepartments,
    });
  };

  const handleAddCompanyClick = (chip: string) => {
    setInvolvedCompanyError(false);
    if (chip.length > 50) return;
    const company = handleChipInput(chip);
    if (!company) return;
    const originalInvolvedCompanysCopy = caseForm.originalInvolvedCompany.length
      ? caseForm.originalInvolvedCompany.split(",")
      : [];
    originalInvolvedCompanysCopy.push(company);
    setCaseForm({
      ...caseForm,
      originalInvolvedCompany: originalInvolvedCompanysCopy.toString(),
    });
  };

  const handleCompanyDelete = (chip: string) => {
    const newOriginalInvolvedCompanys = caseForm.originalInvolvedCompany
      .split(",")
      .filter((item: string) => item !== chip)
      .join();
    setCaseForm({
      ...caseForm,
      originalInvolvedCompany: newOriginalInvolvedCompanys,
    });
  };

  const handleChipInput = (value: string) => {
    let text = value.replace(removeEmojiRegex, "");
    if (text[0] === "," || text[0] === " ") text = text.substring(1);
    if (text[text.length - 1] === "," || text[text.length - 1] === " ")
      text = text.slice(0, -1);
    if (
      (text[0] === "," && text[1] === " ") ||
      (text[0] === " " && text[1] === ",")
    )
      text = text.substring(2);
    if (
      (text[text.length - 1] === "," && text[text.length - 2] === " ") ||
      (text[text.length - 1] === " " && text[text.length - 2] === ",")
    )
      text = text.slice(0, -2);
    text = text.replace(",,", ",");
    text = text.replace(", ", ",");
    text = text.replace(" ,", ",");
    if (text === "," || text === "" || text === " ") return;
    return text;
  };

  const handleChange = (event: any) => {
    const value = event.target.value.replace(removeEmojiRegex, "");
    const name = event.target.name;

    // check if user write spaces at first
    if (isAllTextSpaces(value)) {
      return;
    }
    if (name === "reporterEmail") {
      value ? setEmailError(!emailRegex.test(value)) : setEmailError(false);
    }
    const obj: any = {};
    obj[name] = value;
    setCaseForm({ ...caseForm, ...obj });
  };

  const selectCategory = (category: any) => {
    const categoryPrefix = category.prefix;
    setCaseForm({
      ...caseForm,
      category,
      categoryPrefix,
      categoryId: category.id,
    });
  };

  const selectAmount = (event: any) => {
    const amount = event.target.value;
    setCaseForm({ ...caseForm, amount, originalAmountId: amount.id });
  };

  const selectRelation = (event: any) => {
    const relation = event.target.value;
    setCaseForm({ ...caseForm, relation, originalRelationshipId: relation.id });
  };

  const isValidDate = (date: Moment) => date && date.isValid();

  const handleStartDateChange = (originalStartDate: any) => {
    setStartDate(originalStartDate);
    if (originalStartDate === null || isValidDate(originalStartDate)) {
      setCaseForm({ ...caseForm, originalStartDate });
    }
    checkFormDates(originalStartDate, endDate, caseForm.originalCaseOngoing);
  };

  const handleEndDateChange = (originalEndDate: any) => {
    setEndDate(originalEndDate);
    if (originalEndDate === null || isValidDate(originalEndDate)) {
      setCaseForm({ ...caseForm, originalEndDate });
    }
    checkFormDates(startDate, originalEndDate, caseForm.originalCaseOngoing);
  };

  const checkFormDates = (
    startDate: Moment,
    endDate: Moment,
    ongoing: boolean
  ) => {
    if (
      ongoing ||
      (startDate === null && endDate === null) ||
      (isValidDate(startDate) &&
        isValidDate(endDate) &&
        startDate.isSameOrBefore(endDate))
    ) {
      setDatesInvalid(false);
      return;
    }
    setDatesInvalid(true);
  };

  return (
    <div className="theme-container step-container">
      <div className="row no-gutters mb-50px">
        <div className="col-sm-12 col-md-4 col-xl-4">
          <section className="section">
            <h3 className="section-title">
              {translation.record["description-title"]}
            </h3>
            <p
              className="section-text"
              dangerouslySetInnerHTML={{
                __html: translation.record["description-text"],
              }}
            ></p>
          </section>
        </div>
        <div className="col-sm-12 col-md-8 col-xl-8">
          <input
            type="file"
            ref={inputFileRef}
            onChange={onFileChange}
            style={{ display: "none" }}
            multiple
            accept={fileTypes}
          />
          <TextField
            label={translation.record["description-label"]}
            multiline
            className="text-area-multiline upload-area timeline-upload multiline-upload w-100"
            rows={10}
            variant="filled"
            value={caseForm.originalDescription}
            name="originalDescription"
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={caseForm.filesList.length > 0 ? "icons-wrap" : ""}
                >
                  {configuration.voice_visibility && (
                    <div className="mr-2 microphone">
                      {isRecording ? (
                        <MicIcon onClick={stopRecording} />
                      ) : (
                        <MicNoneIcon onClick={startRecording} />
                      )}
                    </div>
                  )}
                  <AttachFileIcon
                    className="upload-icon mr-1"
                    onClick={clickUpload}
                  />
                </InputAdornment>
              ),
            }}
          />

          <div className="files-preview" ref={uploadEl}>
            {caseForm.filesList.map((file: any, index: any) => {
              return (
                !file.canceled && (
                  <div
                    className={
                      "file-preview " +
                      (caseForm.filesList.length > 4 && isMobile ? "pt-2" : "")
                    }
                    key={index}
                  >
                    <StyledTooltip title={file.name} placement="bottom">
                      <img
                        src={
                          file.src
                            ? file.src
                            : icons[getFileInfo(file.type).fileType]
                        }
                        alt=""
                      />
                    </StyledTooltip>
                    <div
                      className="file-delete"
                      onClick={() => deleteFile(index)}
                    >
                      <ClearIcon />
                    </div>
                  </div>
                )
              );
            })}
          </div>

          <div
            className={`d-flex ${
              configuration.voice_visibility
                ? "justify-content-between"
                : "justify-content-end"
            }`}
          >
            {configuration.voice_visibility && (
              <div className="d-flex align-items-center mt-1 infos-text">
                <img src={icons.info} alt="" className="mr-2 hint-icon" />
                <div
                  className="input-hint"
                  dangerouslySetInnerHTML={{
                    __html:
                      translation.record["relevant information on desc field"],
                  }}
                ></div>
              </div>
            )}

            <div className="file-upload-counts mt">
              <div className="d-flex justify-content-end">
                <div
                  className={
                    "input-char-count files-number " +
                    (caseForm.filesList.length > maxFiles ? "invalid" : "")
                  }
                >
                  {caseForm.filesList.length}/{maxFiles}{" "}
                  {translation.record["files"]}
                </div>
                <div className="input-char-count file-types">
                  (PDF max. 20MB; JPG, PNG max. 8MB; MP4, MP3, max. 200MB)
                </div>
              </div>

              <div
                className={
                  "input-char-count comment-chars " +
                  (caseForm.originalDescription &&
                  !isValidDescription(caseForm.originalDescription)
                    ? "invalid"
                    : "")
                }
              >
                {caseForm.originalDescription.length}/{maxChar}{" "}
                {translation.record["character"]}
              </div>
            </div>
          </div>
        </div>
        {blobURL !== "" && (
          <div className="mx-auto mt-1">
            <audio src={blobURL} controls />
          </div>
        )}
      </div>
      <div className="row no-gutters mb-50px">
        <div className="col-sm-12 col-md-5 col-lg-4">
          <section className="section">
            <h3 className="section-title">
              {translation.record["category-title"]}
            </h3>
            <p
              className="section-text"
              dangerouslySetInnerHTML={{
                __html: translation.record["category-text"],
              }}
            ></p>
          </section>
        </div>
        <div className="col-sm-12 col-md-7 col-lg-8">
          <div className="category-labels">
            {categories.map((category: any, index: any) => {
              return (
                <div
                  className="category-label"
                  key={index}
                  onClick={selectCategory.bind(null, category)}
                >
                  <LabelRoundComponent
                    label={category.name}
                    key={index}
                    clickable={true}
                    active={caseForm.categoryId === category.id}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="row no-gutters mb-50px">
        <div className="col-sm-12 col-md-5 col-lg-4">
          <section className="section">
            <h3 className="section-title">
              {translation.record["anonymity-title"]}
            </h3>
            <p
              className="section-text"
              dangerouslySetInnerHTML={{
                __html: translation.record["anonymity-text"],
              }}
            ></p>
          </section>
        </div>
        <div className="col-sm-12 col-md-7 col-lg-8">
          {configuration.report_anonymous && (
            <div className="mb-2 row mx-0" style={{ marginLeft: "-10px" }}>
              <FormGroup>
                <FormControlLabel
                  className="switch-step2"
                  control={
                    <Switch
                      checked={!caseForm.isAnonymous}
                      onChange={() => {
                        setCaseForm({
                          ...caseForm,
                          isAnonymous: !caseForm.isAnonymous,
                        });
                      }}
                      color="primary"
                      name="is anonymous"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label={translation.record["Share personal data"]}
                />
              </FormGroup>
            </div>
          )}

          <div className="row">
            <div className="col-sm-6 opt-info-field">
              <TextField
                className="w-100"
                label={translation.record["name-username-label"]}
                variant="filled"
                name="reporterName"
                value={caseForm.reporterName}
                onChange={handleChange}
                inputProps={{
                  maxLength: 50,
                }}
                disabled={caseForm.isAnonymous}
                autoComplete="off"
              />
            </div>
            <div className="col-sm-6 opt-info-field">
              <TextField
                className="w-100"
                label={translation.record["email-label"]}
                variant="filled"
                name="reporterEmail"
                value={caseForm.reporterEmail}
                onChange={handleChange}
                error={emailError ? true : false}
                inputProps={{
                  maxLength: 50,
                }}
                disabled={caseForm.isAnonymous}
                autoComplete="off"
              />
            </div>
          </div>

          <div className="row">
            {!caseForm.isAnonymous && (
              <div className=" col-sm-6 d-flex align-items-start infos-text">
                <img src={icons.info} alt="" className="mr-2 hint-icon" />
                <div
                  className="input-hint"
                  dangerouslySetInnerHTML={{
                    __html:
                      translation.record[
                        "relevant information on anonymity field"
                      ],
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>

      {configuration.optional_fields_visibility && (
        <div className="row no-gutters mb-50px opt-info-section">
          <div className="col-sm-12 col-md-5 col-lg-4">
            <section className="section">
              <h3 className="section-title">
                {translation.record["optional-info-title"]}
              </h3>
              <p
                className="section-text"
                dangerouslySetInnerHTML={{
                  __html: translation.record["optional-info-text"],
                }}
              ></p>
              <p className="section-text">
                {translation.record["the fields are optional"]}
              </p>
            </section>
          </div>
          <div className="col-sm-12 col-md-7 col-lg-8">
            <div className="row">
              <div className="col-sm-6 opt-info-field">
                <ChipInput
                  value={
                    caseForm.originalInvolvedCompany.length
                      ? caseForm.originalInvolvedCompany.split(",")
                      : []
                  }
                  onAdd={(chip) => handleAddCompanyClick(chip)}
                  onDelete={(chip) => handleCompanyDelete(chip)}
                  className="w-100 chip-input-field"
                  label={translation.record["involved-company-label"]}
                  variant="filled"
                  onUpdateInput={(e: any) => {
                    setInvolvedCompanyError(
                      e.target.value.length > 50 ? true : false
                    );
                  }}
                  error={involvedCompanyError}
                  fullWidth
                />
                {involvedCompanyError && (
                  <p className="error-msg">
                    {
                      translation.record[
                        "you can't use more than 50 characters in this field"
                      ]
                    }
                  </p>
                )}
              </div>
              <div className="col-sm-6 opt-info-field">
                <FormControl className="select-input select-grey w-100">
                  <InputLabel id="relation">
                    {translation.record["relation-label"]}
                  </InputLabel>
                  <Select
                    labelId="relation"
                    id="relation-select"
                    name="originalRelationshipId"
                    value={caseForm.relation}
                    onChange={selectRelation}
                    IconComponent={ExpandMoreIcon}
                  >
                    {relations.map((item: any, index: any) => {
                      return (
                        <MenuItem value={item} key={index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="mb-1">
              <FormControlLabel
                style={{ color: "#5a5c4f" }}
                control={
                  <Checkbox
                    checked={caseForm.originalCaseOngoing}
                    onChange={(e: any) => {
                      setCaseForm({
                        ...caseForm,
                        originalCaseOngoing: e.target.checked,
                        originalEndDate: null,
                      });
                      setEndDate(null);
                      checkFormDates(startDate, endDate, e.target.checked);
                    }}
                    name="originalCaseOngoing"
                    color="primary"
                  />
                }
                label={translation.record["case is still active"]}
              />
            </div>

            <div className="row">
              <div className="col-6 opt-info-field opt-info-start-date">
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  libInstance={moment}
                  locale={locale}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="filled"
                    format="DD.MM.YYYY"
                    autoOk
                    name="originalStartDate"
                    value={startDate}
                    onChange={handleStartDateChange}
                    className="w-100"
                    label={translation.record["start-date-label"]}
                    autoComplete="off"
                    keyboardIcon={<EventOutlinedIcon />}
                    maxDate={isValidDate(endDate) ? endDate : undefined}
                    maxDateMessage={
                      endDate
                        ? translation.record[
                            "the start date should be less than the end date"
                          ]
                        : ""
                    }
                    minDateMessage=""
                    invalidDateMessage={
                      translation.record["invalid date format"]
                    }
                    error={
                      (startDate && !startDate.isValid()) ||
                      (isValidDate(endDate) && datesInvalid)
                    }
                  />
                </MuiPickersUtilsProvider>
              </div>
              {!caseForm.originalCaseOngoing && (
                <div className="col-6 opt-info-field opt-info-end-date">
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    libInstance={moment}
                    locale={locale}
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      inputVariant="filled"
                      format="DD.MM.YYYY"
                      autoOk
                      name="originalEndDate"
                      value={endDate}
                      onChange={handleEndDateChange}
                      className="w-100"
                      autoComplete="off"
                      label={translation.record["end-date-label"]}
                      keyboardIcon={<EventOutlinedIcon />}
                      minDate={isValidDate(startDate) ? startDate : undefined}
                      minDateMessage={""}
                      maxDateMessage={""}
                      invalidDateMessage={
                        translation.record["invalid date format"]
                      }
                      error={
                        (endDate && !endDate.isValid()) ||
                        (isValidDate(startDate) && datesInvalid)
                      }
                    />
                  </MuiPickersUtilsProvider>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12 opt-info-field rel-wrap">
                <ChipInput
                  value={
                    caseForm.originalInvolvedPersons.length
                      ? caseForm.originalInvolvedPersons.split(",")
                      : []
                  }
                  onAdd={(chip) => handleAddPersonClick(chip)}
                  onDelete={(chip) => handlePersonDelete(chip)}
                  className="w-100 chip-input-field"
                  label={translation.record["involved-people-label"]}
                  onUpdateInput={(e: any) => {
                    setInvolvedPersonError(
                      e.target.value.length > 50 ? true : false
                    );
                  }}
                  error={involvedPersonError}
                  variant="filled"
                />
                {involvedPersonError && (
                  <p className="error-msg">
                    {
                      translation.record[
                        "you can't use more than 50 characters in this field"
                      ]
                    }
                  </p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 opt-info-field rel-wrap">
                <ChipInput
                  value={
                    caseForm.originalInvolvedDepartment.length
                      ? caseForm.originalInvolvedDepartment.split(",")
                      : []
                  }
                  onAdd={(chip) => handleAddDepartmentClick(chip)}
                  onDelete={(chip) => handleDepartmentDelete(chip)}
                  className="w-100 chip-input-field"
                  label={translation.record["involved-department-label"]}
                  onUpdateInput={(e: any) => {
                    setInvolvedDepartmentError(
                      e.target.value.length > 50 ? true : false
                    );
                  }}
                  error={involvedDepartmentError}
                  variant="filled"
                />
                {involvedDepartmentError && (
                  <p className="error-msg">
                    {
                      translation.record[
                        "you can't use more than 50 characters in this field"
                      ]
                    }
                  </p>
                )}
              </div>
              <div className="col-sm-6 opt-info-field">
                <FormControl className="select-input select-grey w-100">
                  <InputLabel id="amount-of-damage">
                    {translation.record["amount-damage-label"]}
                  </InputLabel>
                  <Select
                    labelId="amount-of-damage"
                    name="originalAmountId"
                    value={caseForm.amount}
                    onChange={selectAmount}
                    IconComponent={ExpandMoreIcon}
                  >
                    {amounts.map((item: any, index: any) => {
                      return (
                        <MenuItem value={item} key={index}>
                          {item.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex flex-row justify-content-end mt-5">
        <Button
          className="btn-secondary-theme btn-prev"
          variant="outlined"
          color="primary"
          onClick={setActiveStep.bind(null, 0)}
        >
          {translation.record["back"]}
        </Button>
        <Button
          className="btn-primary-theme btn-next"
          variant="contained"
          color="primary"
          onClick={() => {
            setActiveStep(2);
            props.scrollTop();
          }}
          disabled={
            !isValidDescription(caseForm.originalDescription) ||
            caseForm.filesList.length > 5 ||
            !caseForm.categoryId ||
            datesInvalid ||
            (!caseForm.isAnonymous && emailError) ||
            (!caseForm.isAnonymous && !caseForm.reporterEmail.length) ||
            (!caseForm.isAnonymous && caseForm.reporterName.length === 0) ||
            (startDate && !startDate.isValid()) ||
            involvedCompanyError ||
            involvedDepartmentError ||
            involvedPersonError
          }
        >
          <ArrowForwardIcon /> {translation.record["review"]}
        </Button>
      </div>
    </div>
  );
}
