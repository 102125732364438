import i18n from "../../i18n";
import axios from "axios";
import { config } from "../../config";

export class LangService {
  public getCurrentLang = (): string => {
    return i18n.language;
  }

  public setCurrentLang = (lang: string): void => {
    i18n.changeLanguage(lang);
  }

  public getTranslation = async (): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.get(serverUrl + "configuration/translation");
      return response.data;
    } catch (err) {
      return [];
    }
  };
}
