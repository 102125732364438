import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import "./case-info.component.scoped.scss";

export const CaseInfoComponent = (props: any) => {
  const { icon, image, label, value, loading } = props;
  return (
    <div className="case-info-container">
      {loading && (icon || image) ? (
        <Skeleton
          variant="circle"
          height={40}
          width={40}
          style={{ marginRight: "10px" }}
        />
      ) : (
        <>
          {icon && (
            <div className="case-info-icon">
              <img src={icon} alt="" />
            </div>
          )}
          {image && <img src={image} alt="" className="case-info-image" />}
        </>
      )}
      <div style={{ width: icon || image ? "calc(100% - 50px)" : "100%" }}>
        {loading ? (
          <Skeleton variant="text" />
        ) : (
          <div className="case-info-role">{label}</div>
        )}
        {loading ? (
          <Skeleton variant="text" />
        ) : (
          <div className="case-info-name">{value}</div>
        )}
      </div>
    </div>
  );
};
